.Footer_Head {
  width: 100%;
  height: auto;
  cursor: default;
}

.Footer {
  width: 100%;
  height: 5vh;
  background: #004182;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_1 {
  width: auto;
  height: auto;
  z-index: 999;
  position: relative;
}

.Footer h1 {
  padding: 15px 20px;
  color: #fff;
  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.FooterLinks {
  width: 100%;
  height: 100%;
  background-color: #0d5093;
  cursor: default !important;
}

.Footercomponents {
  margin: auto;
  max-width: 1920px;
  height: auto;
}

.OurSites {
  width: 85%;
  height: auto;
  margin: auto;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.OurSites p {
  width: 151px;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
}

.OurSites a {
  width: 151px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer !important;
  color: #fff;
  border-right: 0.1px solid #fff;
  background-color: #0d5093;
}

.OurSites img {
  width: auto;
  height: 39px;
  margin-left: auto;
}

.Products {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #0d5093;
}

.Products_Link_Section {
  width: 87%;
  margin: auto;
  max-width: 1920px;
  display: flex;
  cursor: default !important;
}

.Product_Links {
  width: min(43.5vw, 837px);
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: #004182;
}

.Product_Links a {
  color: #fff;
  margin-left: 70px;
  font-family: Arial;
  font-size: min(0.8vw, 16px);
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
  cursor: pointer !important;
}

.Products_1,
.Products_2,
.Products_3 {
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
}

.Reachus {
  width: min(28vw, 539px);
  height: 250px;
  background-color: #004182;
  margin-left: auto;
  margin-right: 1%;
}

.Reachus h1 {
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 20px;
  margin-top: 0;
}

.Subscribe {
  width: min(20vw, 391px);
  height: 28px;
  margin-left: 15px;
  margin-top: -20px;
  padding: 15px 20px;
  background-color: #0d5093;
  border: #004182;
  color: rgba(255, 255, 255, 0.2);
  font-family: Arial;
  font-size: min(1vw, 20px);
  font-style: normal;
  font-weight: 400;
}

.SubButton {
  position: absolute;
  width: 130px;
  height: 36px;
  margin-top: -10px;
  margin-left: -155px;
  background: #004182;
  border: #004182;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.Work_Hours {
  width: auto;
  height: auto;
  margin: auto;
  margin-left: 20px;
}

.Work_Hours h2 {
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Work_Hours span {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Contact {
  width: auto;
  height: auto;
  display: grid;
  gap: 18px;
  margin-top: 25px;
  margin-left: 20px;
}

.Contact a {
  text-decoration: none;
  color: #fff;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer !important;
}

.text {
  display: grid;
  grid-template-columns: 0.35fr 0.05fr 0.5fr;
}

label {
  cursor: pointer;
}

.Social {
  width: auto;
  height: auto;
  display: flex;
  gap: min(3.1vw, 60px);
  margin-top: -25px;
  margin-left: 20px;
  justify-content: flex-start;
  align-items: flex-end;
}

.Copyright {
  width: 85%;
  margin: auto;
  color: #fdfdfd;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (min-width: 601px) and (max-width: 1070px) {
  .Footer {
    height: 50px;
  }

  .Footer h1 {
    font-size: 26px;
  }

  .OurSites p {
    font-size: 18px;
  }

  .OurSites a {
    width: 120px;
    height: 30px;
    font-size: 12px;
  }

  .Footer_1 {
    width: auto;
    height: auto;
    background-color: #0d5093;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Products_Link_Section {
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  .Product_Links {
    width: 93vw;
    height: 270px;
    justify-content: space-around;
  }

  .Product_Links a {
    font-size: 14px;
  }

  .Reachus {
    width: 61.5vw;
    margin: auto !important;
  }

  .Subscribe {
    width: 50vw;
    font-size: 18px;
  }

  .Copyright {
    padding: 15px 20px !important;
    margin: 0 !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .Footer {
    height: 50px;
  }

  .Footer h1 {
    font-size: 20px;
  }

  .Footer_Links {
    width: 100%;
    height: auto;
  }

  .Footer_components {
    height: 100%;
    margin-bottom: 0;
    display: flex;
  }

  .OurSites {
    height: 90%;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 20px;
    justify-content: space-around;
    align-items: center;
  }

  .OurSites p {
    font-size: 16px;
    line-height: 15px;
    margin-top: 0;
    text-align: center;
    border-right: none;
    margin-bottom: 10px;
  }

  .OurSites a {
    width: 130px;
    height: 30px;
    font-size: 14px;

    border-right: none;
  }

  .OurSites img {
    width: 60px;
    height: auto;
  }

  .Footer_1 {
    width: 100vw;
    height: auto;
    background-color: #0d5093;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Products_Link_Section {
    height: 100%;
    display: grid;
    justify-content: center;
    gap: 30px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .Product_Links {
    padding: 20px;
    width: 83vw;
    height: 170px;
    justify-content: space-around;
  }

  .Product_Links a {
    font-size: 10px;
    margin-left: 0;
    line-height: normal;
  }
  .Products_1,
  .Products_2,
  .Products_3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .Products_3 a {
    margin-left: 12px;
  }

  .Reachus {
    width: 91.5vw;
    height: auto;
    margin: auto !important;
  }
  .Reachus h1 {
    font-size: 10px;
  }

  .Subscribe {
    width: 70vw;
    height: 10px;
    font-size: 10px;
  }

  .SubButton {
    width: 60px;
    height: 20px;
    font-size: 10px;
    margin-left: -80px;
    position: relative;
  }

  .Work_Hours h2 {
    font-size: 14px;
  }

  .Work_Hours span {
    font-size: 12px;
  }

  .Social img {
    width: 10px;
    height: 10px;
  }

  .Social {
    margin-left: 10px;
  }

  .Products {
    height: 58.2vh;
    padding-top: 0px;
  }

  .Copyright {
    padding: 15px 20px !important;
    margin: 0 !important;
  }

  .Copyright {
    margin-left: 40px;
    font-size: 10px;
  }

  .Contact {
    gap: 6px;
    margin-top: 10px;
  }

  .Contact a {
    font-size: 10px;
  }
}

@media screen and (min-width: 260px) and (max-width: 359px) {
  .Footer {
    height: 50px;
  }

  .Footer h1 {
    font-size: 18px;
  }
  .Footer_Links {
    width: 100%;
    height: auto;
  }

  .Footer_components {
    height: 100%;
    margin-bottom: 0;
    display: flex;
  }

  .OurSites {
    height: 90%;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 20px;
    justify-content: space-around;
    align-items: center;
  }

  .OurSites p {
    font-size: 16px;
    line-height: 15px;
    margin-top: 0;
    text-align: center;
    border-right: none;
    margin-bottom: 10px;
  }

  .OurSites a {
    width: 130px;
    height: 30px;
    font-size: 14px;
    border-right: none;
  }

  .OurSites img {
    width: 30px;
    height: auto;
  }

  .Footer_1 {
    width: 100vw;
    height: auto;
    background-color: #0d5093;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Products_Link_Section {
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  .Product_Links {
    padding: 20px;
    width: 83vw;
    height: 170px;
    justify-content: space-around;
  }

  .Product_Links a {
    font-size: 10px;
    margin-left: 0;
    line-height: normal;
  }
  .Reachus {
    width: 91.5vw;
    height: auto;
  }

  .Reachus h1 {
    font-size: 10px;
  }
  .Products_1,
  .Products_2,
  .Products_3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .Products_3 a {
    margin-left: 12px;
  }

  .Products_1,
  .Products_2,
  .Products_3 {
    width: 80px;
  }

  .Subscribe {
    width: 35vw;
    font-size: 18px;
  }

  .SubButton {
    width: 50px;
    font-size: 8px;
    margin-left: -55px;
  }

  .Social img {
    width: 12px;
    height: 12px;
  }

  .Social {
    margin-top: 5px;
    margin-left: 10px;
  }

  .Copyright {
    padding: 15px 20px !important;
    margin: 0 !important;
    font-size: 8px;
  }

  .Work_Hours h2 {
    font-size: 14px;
  }

  .Work_Hours span {
    font-size: 12px;
  }

  .Contact a {
    font-size: 8px;
  }
}
