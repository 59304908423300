.Background {
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
}

.Background h1 {
    color: #FFF;
    font-family: Arial;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
    margin-top: 30vh;
}

.Background h3 {
    color: #FFF;
    font-family: Arial;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    margin-top: -120px;

}

.Background p {
    color: #FFF;
    text-align: center;
    font-family: Arial;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    width: 40%;
    margin: auto;
    margin-top: -25px;
}

.Background button {
    color: #FFF;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: auto;
    width: 160px;
    height: 60.961px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #0D5093;
    border: #0D5093;
    margin-top: -20px;

}

@media screen and (min-width:300px) and (max-width:1000px) {
    .Background h1 {
        font-size: 90px;
    }

    .Background h3 {
        font-size: 32px;
        margin-top: -70px;
    }

    .Background p {
        font-size: 14px;
        margin-top: -90px;
        line-height: 25px;
        width: 70%;
    }

    .Background button {
        font-size: 12px;
        width: 100px;
        height: 40px;
    }
}