.Header {
  width: 100%;
  height: min(90px, 9.22vh);
  top: 0;
  background-color: #fff;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 3px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: default;
}

.Header_Elements {
  width: 1920px;
  height: min(90px, 9.22vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
}

.Head_Image_container {
  width: auto;
  height: auto;
}

.Head_Image_container a {
  width: auto;
  height: auto;
}

.Safe_Logo {
  width: 150px;
  margin-left: 130px;
  padding: 15px 15px;
}

.Navigation_Bar {
  width: auto;
  height: auto;
  display: flex;
  gap: 5px;
  margin-right: 140px;
}

.Navigation_Bar a {
  font-size: 14px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 24px;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer !important;
}

.Mobile_Header {
  display: none;
}

.nav-bar:hover {
  color: #0c449c;
}

.nav-bar.active {
  color: #0c449c;
}

@media screen and (min-width: 1071px) and (max-width: 1360px) {
  .Navigation_Bar a {
    font-size: 12px;
  }

  .Navigation_Bar {
    gap: initial;
    margin-right: 225px;
  }
}

@media screen and (min-width: 260px) and (max-width: 1070px) {
  .Navigation_Bar {
    display: none;
  }

  .Header {
    align-items: baseline;
  }

  .Safe_Logo {
    margin-left: 25px;
    width: 120px;
    padding-left: 0;
  }

  .Mobile_Header {
    width: auto;
    height: 9.22vh;
    display: flex;
  }

  .Horizontal_Menu {
    align-items: center;
    display: flex;
    width: auto;
    height: 100%;
    margin: auto;
    margin-right: 9px;
    background-color: #fff;
    outline: none;
    border: none;
  }

  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }

  .line {
    fill: none;
    stroke: #00193b;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }

  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .Mobile_Menu {
    width: 100%;
    height: 100%;
    position: absolute;
    position: fixed;
    margin-top: min(90px, 9.22vh);
    z-index: 100;
    background-color: white;
  }

  .Menu {
    width: auto;
    height: auto;
    display: grid;
  }

  .Menu a {
    font-size: 16px;
    padding: 20px 20px;
    color: #404040;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid #f2f2f2;
  }
}
