.section_1 {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_1_Body_Section {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_1_width {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin: auto;
  margin-top: 80px;
}

.section_1_first {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  background-color: rgb(204, 204, 204);
}

@media screen and (min-width: 1250px) and (max-width: 1479px) {
  .section_1 {
    width: 100%;
    height: 500px;
    margin-top: 90px;
  }

  .section_1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_1_width {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .section_1_first {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1249px) {
  .section_1 {
    width: 100%;
    height: 450px;
    margin-top: 30px;
  }

  .section_1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_1_width {
    width: 100%;
    height: 100%;
    margin-top: 45px;
  }

  .section_1_first {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .section_1 {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .section_1_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_1_width {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin: auto;
    margin-top: 40px;
  }

  .section_1_first {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    background-color: rgb(204, 204, 204);
  }
}

@media screen and (min-width: 500px) and (max-width: 699px) {
  .section_1 {
    width: 100%;
    height: 400px;
  }

  .section_1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_1_width {
    width: 100%;
    height: 100%;
    margin-top: 40px;
  }

  .section_1_first {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 300px) and (max-width: 499px) {
  .section_1 {
    width: 100%;
    height: 330px;
    margin-top: 9.22vh;
  }

  .section_1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_1_width {
    width: 100%;
    height: 100%;
    margin-top: 0px;
  }

  .section_1_first {
    width: 100%;
    height: 100%;
  }
}

.section_3 {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section3_Body_Section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section3_width {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 110px;
}

.section_3_box {
  width: 88%;
  height: 230px;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.section3_box_1 {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: -20px;
}

.address {
  width: 88%;
  height: 130px;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.address h1 {
  color: #2e2e2e;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  line-height: 2;
  margin: auto;
}

.address a {
  color: #0d5093;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  line-height: 1.4;
  margin: auto;
  text-align: left;
  text-decoration: none;
}

.address p {
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  line-height: 1.5;
  margin: auto;
  text-align: left;
}

.section3_box_2 {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: -20px;
}

.line {
  width: 1px;
  height: 80%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  background-color: #d9d9d9;
}

.call {
  width: 88%;
  height: 130px;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.call h2 {
  color: #2e2e2e;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  line-height: normal;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 10px;
}

.call span {
  color: #0d5093;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin: auto;
  text-align: left;
}

.call a {
  color: #565656;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  text-align: left;
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-template-columns: 0.3fr 0.05fr 0.5fr;
  margin-top: 10px;
}

.Timings {
  color: #2e2e2e !important;
  font-family: Arial !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.section3_box_3 {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: -20px;
}

.mail {
  width: 88%;
  height: 130px;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.mail h2 {
  color: #2e2e2e;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  line-height: 2;
  margin: auto;
  margin-top: 0px;
}

.mail span {
  color: #0d5093;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.8;
  margin: auto;
  text-align: left;
}

.mail a {
  color: #565656;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: auto;
  text-align: left;
  text-decoration: none;
}

@media screen and (min-width: 1024px) and (max-width: 1249px) {
  .section_3 {
    width: 100%;
    height: 350px;
  }

  .section3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section3_width {
    width: 100%;
    height: 100%;
  }

  .section_3_box {
    width: 88%;
    height: 200px;
    border-radius: 12px;
    margin-bottom: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .section3_box_1 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .address {
    width: 87%;
    height: 130px;
  }

  .address h1 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    line-height: 2;
  }

  .address a {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    line-height: 1.5;
  }

  .address p {
    color: #565656;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    line-height: 1.5;
    margin: auto;
    text-align: left;
  }

  .section3_box_2 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .line {
    width: 1px;
    height: 80%;
  }

  .call {
    width: 88%;
    height: 130px;
  }

  .call h2 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .call span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    text-align: left;
  }

  .call a {
    color: #565656;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
  }

  .section3_box_3 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .mail {
    width: 88%;
    height: 130px;
  }

  .mail h2 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
    margin-top: 0px;
  }

  .mail span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
  }

  .mail a {
    color: #565656;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
  }
}

@media screen and (min-width: 850px) and (max-width: 1023px) {
  .section_3 {
    width: 100%;
    height: 350px;
  }

  .section3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section3_width {
    width: 100%;
    height: 100%;
  }

  .section_3_box {
    width: 90%;
    height: 200px;
    border-radius: 12px;
    margin-bottom: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .section3_box_1 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .address {
    width: 90%;
    height: 130px;
  }

  .address h1 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    line-height: 2;
  }

  .address a {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    line-height: 1.5;
  }

  .address p {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    line-height: 1.5;
    margin: auto;
    text-align: left;
  }

  .section3_box_2 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .line {
    width: 1px;
    height: 80%;
  }

  .call {
    width: 90%;
    height: 130px;
  }

  .call h2 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .call span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    text-align: left;
  }

  .call a {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
  }

  .section3_box_3 {
    width: 33%;
    height: 100%;
    margin-top: -15px;
  }

  .mail {
    width: 90%;
    height: 130px;
  }

  .mail h2 {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
    margin-top: 0px;
  }

  .mail span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
  }

  .mail a {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
}

@media screen and (min-width: 550px) and (max-width: 849px) {
  .section_3 {
    width: 100%;
    height: 500px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section3_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section3_width {
    width: 80%;
    height: 100%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 60px;
  }

  .section_3_box {
    width: 85%;
    height: 400px;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .section3_box_1 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .address {
    width: 88%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .address h1 {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
  }

  .address a {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    line-height: 1.4;
    margin: auto;
    text-align: left;
    text-decoration: none;
  }

  .address p {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    line-height: 1.5;
    margin: auto;
    text-align: left;
  }

  .section3_box_2 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .line {
    width: 90%;
    height: 1px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    background-color: #d9d9d9;
  }

  .call {
    width: 88%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .call h2 {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .call span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    text-align: left;
  }

  .call a {
    color: #565656;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: auto;
    text-align: left;
    text-decoration: none;
    margin-top: 10px;
  }

  .section3_box_3 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .mail {
    width: 88%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .mail h2 {
    color: #2e2e2e;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
    margin-top: 0px;
  }

  .mail span {
    color: #0d5093;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.8;
    margin: auto;
    text-align: left;
  }

  .mail a {
    color: #565656;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: auto;
    text-align: left;
    text-decoration: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 549px) {
  .section_3 {
    width: 100%;
    height: 580px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section3_Body_Section {
    width: 1920px;
    height: 100%;
    margin-top: 60px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section3_width {
    width: 80%;
    height: 100%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_box {
    width: 90%;
    height: 460px;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .section3_box_1 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .address {
    width: 90%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .address h1 {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
  }

  .address a {
    color: #0d5093;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    line-height: 1.4;
    margin: auto;
    text-align: left;
    text-decoration: none;
  }

  .address p {
    color: #2e2e2e;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    line-height: 1.5;
    margin: auto;
    text-align: left;
  }

  .section3_box_2 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .line {
    width: 90%;
    height: 1px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    background-color: #d9d9d9;
  }

  .call {
    width: 90%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .call h2 {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .call span {
    color: #0d5093;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    text-align: left;
  }

  .call a {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin: auto;
    text-align: left;
    text-decoration: none;
    margin-top: 10px;
  }

  .section3_box_3 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .mail {
    width: 90%;
    height: 130px;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .mail h2 {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    line-height: 2;
    margin: auto;
    margin-top: 0px;
  }

  .mail span {
    color: #0d5093;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8;
    margin: auto;
    text-align: left;
  }

  .mail a {
    color: #565656;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin: auto;
    text-align: left;
    text-decoration: none;
  }
}
