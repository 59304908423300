.section2 {
    width: 100%;
    height: 360px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2Body_Section {
    width: 5020px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2width {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2_box {
    width: 1000px;
    height: 340px;
    border-radius: 8px;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    margin: auto;
    margin-top: -120px;
    background-color: #DCEAFF;
}

.section_2_box h2 {
    color: #2E2E2E;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.3;
    margin: auto;
    margin-bottom: 40px;
    margin-left: 56px;
}

.section_2_box p {
    color: #565656;
    font-size: 17px;
    font-weight: 500;
    text-align: justify;
    margin: auto;
    margin-left: 56px;
    margin-right: 56px;
    margin-top: -20px;
    line-height: 25px;
}

@media screen and (min-width:1280px) and (max-width:1599px) {
    .section2 {
        width: 100%;
        height: 360px;
    }

    .section_2Body_Section {
        width: 5020px;
        height: 100%;
    }

    .section_2width {
        width: 100%;
        height: 100%;
    }

    .section_2_box {
        width: 1000px;
        height: 340px;
        border-radius: 8px;
        margin-top: -100px;
        background-color: #DCEAFF;
    }

    .section_2_box h2 {
        color: #2E2E2E;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.3;
        margin: auto;
        margin-bottom: 40px;
        margin-left: 56px;
    }

    .section_2_box p {
        color: #565656;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
        margin: auto;
        margin-left: 56px;
        margin-right: 56px;
        margin-top: -20px;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .section2 {
        width: 100%;
        height: 340px;
    }

    .section_2Body_Section {
        width: 5020px;
        height: 100%;
    }

    .section_2width {
        width: 100%;
        height: 100%;
    }

    .section_2_box {
        width: 900px;
        height: 330px;
        border-radius: 8px;
        margin-top: -80px;
        background-color: #DCEAFF;
    }

    .section_2_box h2 {
        color: #2E2E2E;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.3;
        margin: auto;
        margin-bottom: 30px;
        margin-left: 56px;
    }

    .section_2_box p {
        color: #565656;
        font-size: 17px;
        font-weight: 500;
        text-align: justify;
        margin: auto;
        margin-left: 56px;
        margin-right: 56px;
        margin-top: -10px;
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .section2 {
        width: 100%;
        height: 400px;
    }

    .section_2Body_Section {
        width: 5020px;
        height: 100%;
    }

    .section_2width {
        width: 100%;
        height: 100%;
    }

    .section_2_box {
        width: 700px;
        height: 350px;
        margin: auto;
        margin-top: 40px;
        border-radius: 8px;
        background-color: #DCEAFF;
    }

    .section_2_box h2 {
        color: #2E2E2E;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.3;
        margin: auto;
        margin-bottom: 30px;
        margin-left: 56px;
    }

    .section_2_box p {
        color: #565656;
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
        margin: auto;
        margin-left: 56px;
        margin-right: 56px;
        margin-top: -10px;
    }
}

@media screen and (min-width:500px) and (max-width:767px) {
    .section2 {
        width: 100%;
        height: 450px;
    }

    .section_2Body_Section {
        width: 5020px;
        height: 100%;
    }

    .section_2width {
        width: 100%;
        height: 100%;
    }

    .section_2_box {
        width: 470px;
        height: 420px;
        margin: auto;
        margin-top: 40px;
        border-radius: 8px;
        background-color: #DCEAFF;
    }

    .section_2_box h2 {
        color: #2E2E2E;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
        margin: auto;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .section_2_box p {
        color: #565656;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        margin: auto;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -10px;
    }
}

@media screen and (min-width:300px) and (max-width:499px) {
    .section2 {
        width: 100%;
        height: 550px;
    }

    .section_2Body_Section {
        width: 5020px;
        height: 100%;
    }

    .section_2width {
        width: 100%;
        height: 100%;
    }

    .section_2_box {
        width: 100%;
        height: 550px;
        margin: auto;
        margin-top: 4px;
        background-color: #ffffff;
    }

    .section_2_box h2 {
        color: #2E2E2E;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
        margin: auto;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .section_2_box p {
        color: #565656;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
        margin: auto;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
    }
}

.Section_3 {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;

}

.Section_3_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.Section_3_width {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content h3 {
    width: 758px;
    height: 100px;
    font-size: 48px;
    color: #2E2E2E;
    margin: auto;
    margin-left: 0px;
    font-weight: 700;
}

.section_3_content p {
    width: 100%;
    text-align: left;
    text-align: justify;
    font-size: 18px;
    line-height: 2;
    color: #565656;
    margin: auto;
    margin-top: -40px;
    font-weight: 400;
}

.section_3_content span {
    /* width: 600px;
    padding: 5px;
    background-color: #0D5093; */
    text-align: center;
    font-size: 18px;
    color: #565656;
    margin: auto;
    font-weight: 700;
}

@media screen and (min-width:1280px) and (max-width:1599px) {
    .Section_3 {
        width: 100%;
        height: 350px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 85%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 758px;
        height: 100px;
        font-size: 48px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 18px;
        line-height: 1.8;
        margin-top: -40px;
        font-weight: 400;
    }

    .section_3_content span {
        /* width: 600px;
        padding: 5px;
        background-color: #0D5093; */
        text-align: center;
        font-size: 18px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .Section_3 {
        width: 100%;
        height: 350px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 85%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 758px;
        height: 100px;
        font-size: 40px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 16px;
        line-height: 1.6;
        margin-top: -50px;
        font-weight: 400;
    }

    .section_3_content span {
        /* width: 600px;
        padding: 5px;
        background-color: #0D5093; */
        text-align: center;
        font-size: 18px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}

@media screen and (min-width:700px) and (max-width:1023px) {
    .Section_3 {
        width: 100%;
        height: 350px;
        margin-top: 20px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 90%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 600px;
        height: 100px;
        font-size: 36px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 16px;
        line-height: 1.8;
        margin-top: -50px;
        font-weight: 400;
    }

    .section_3_content span {
        /* width: 600px;
        padding: 5px;
        background-color: #0D5093; */
        text-align: center;
        font-size: 16px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}

@media screen and (min-width:500px) and (max-width:699px) {
    .Section_3 {
        width: 100%;
        height: 380px;
        margin-top: 40px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 90%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 480px;
        height: 100px;
        font-size: 30px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 16px;
        line-height: 1.8;
        margin-top: -50px;
        font-weight: 400;
    }

    .section_3_content span {
        /* width: 600px;
        padding: 5px;
        background-color: #0D5093; */
        text-align: center;
        font-size: 14px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}

@media screen and (min-width:400px) and (max-width:499px) {
    .Section_3 {
        width: 100%;
        height: 480px;
        margin-top: 0px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 90%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 300px;
        height: 100px;
        margin: auto;
        margin-bottom: 20px;
        font-size: 26px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 16px;
        line-height: 1.6;

        font-weight: 400;
    }

    .section_3_content span {
        /* width: 600px;
        padding: 5px;
        background-color: #0D5093; */
        text-align: center;
        font-size: 16px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}

@media screen and (min-width:300px) and (max-width:399px) {
    .Section_3 {
        width: 100%;
        height: 550px;
        margin-top: 10px;
    }

    .Section_3_Body_Section {
        width: 5020px;
        height: 100%;
    }

    .Section_3_width {
        width: 90%;
        height: 100%;
    }

    .section_3_content {
        width: 100%;
        height: 100%;
    }

    .section_3_content h3 {
        width: 300px;
        height: 100px;
        font-size: 26px;
        margin-left: 0px;
        font-weight: 700;
    }

    .section_3_content p {
        width: 100%;
        font-size: 16px;
        line-height: 1.6;

        font-weight: 400;
    }

    .section_3_content span {
        text-align: center;
        font-size: 16px;
        color: #565656;
        margin: auto;
        font-weight: 700;
    }
}