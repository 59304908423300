header {
  top: 0;
  width: 100%;
  position: fixed;
  background-color: #f2f2f2;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 10;
}

.header_change {
  background-color: #f2f2f2;
  position: fixed;
  width: 100%;
  box-shadow: 8px 8px 10px 0 rgba(117, 117, 117, 0.1);
  transition: 0.2s;
}

.container {
  display: flex;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.2s;
  margin: auto;
  background-color: #f2f2f2;
  margin-top: 90px;
}

.Header_Menu {
  width: 92%;
  height: 100%;
}

.Scroll-Button-mobile-left {
  display: none;
}

.Scroll-Button-mobile-Right {
  display: none;
}

.wrapper {
  width: 100%;
  height: 45px;
  display: flex;
  overflow-x: auto;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Menu_Box {
  min-width: 5%;
  height: 30px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Active {
  text-decoration: none;
  text-align: center;
  color: #a1a1a1;
  font-size: 13px;
}

.Menu_Heading p:hover {
  color: #0d5093;
  font-weight: 700;
}

.Active.active {
  color: #0c449c;
  font-weight: 700;
}

@media screen and (min-width: 800px) and (max-width: 1069px) {
  header {
    top: 25px;
    width: 100%;
    position: fixed;
    background-color: #f2f2f2;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .header_change {
    background-color: #f2f2f2;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 8px 8px 10px 0 rgba(117, 117, 117, 0.1);
    transition: 0.2s;
  }

  .container {
    height: auto;
    display: flex;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    transition: 0.2s;
    margin: auto;
    background-color: #f2f2f2;
    margin-top: 60px;
  }

  .Header_Menu {
    width: 92%;
    height: 100%;
  }

  .Scroll-Button-mobile-left {
    display: none;
  }

  .Scroll-Button-mobile-Right {
    display: none;
  }

  .wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    overflow-x: auto;
  }

  .wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .Menu_Box {
    min-width: 10%;
    height: 45px;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Active {
    text-decoration: none;
  }

  .Menu_Box p:hover {
    color: #0d5093;
    font-weight: 600;
  }

  .Menu_Box p:active {
    color: #0d5093;
    font-weight: 600;
  }

  .Menu_Heading {
    width: 100%;
    height: auto;
  }

  .Menu_Heading p {
    text-align: center;
    color: #a1a1a1;
    font-size: 13px;
    margin: auto;
    margin-top: 12px;
  }
}

@media screen and (min-width: 300px) and (max-width: 799px) {
  .Bg_Color {
    width: 100%;
    height: 45px;
  }

  header {
    width: 100%;

    position: fixed;
    background-color: #f2f2f2;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .header_change {
    background-color: #f2f2f2;
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  .container {
    width: 100%;
    height: 62px;
    margin-top: 9.22vh;
  }

  .Header_Menu {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .Scroll-Button-mobile-left {
    display: block;
    width: 6%;
    height: 100%;
    display: flex;
  }

  .Scroll-Button-mobile-left:hover {
    border-right: 1px solid rgb(202, 201, 201);
  }

  .Scroll-Button-mobile-left button {
    border: none;
    margin: auto;
    height: 100%;
    background-color: transparent;
  }

  .Scroll-Button-mobile-left img {
    width: 10px;
  }

  .Scroll-Button-mobile-Right {
    display: block;
    width: 6%;
    height: 100%;
    display: flex;
  }

  .Scroll-Button-mobile-Right:hover {
    border-left: 1px solid rgb(202, 201, 201);
  }

  .Scroll-Button-mobile-Right button {
    border: none;
    margin: auto;
    background-color: transparent;
    height: 100%;
  }

  .Scroll-Button-mobile-Right img {
    width: 10px;
  }

  .wrapper {
    width: 88%;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .Menu_Box {
    min-width: 95px;
    height: 100%;
    margin: 1px;
  }

  .Menu_Box a {
    text-decoration: none;
  }

  .Menu_Heading {
    width: 100%;
    height: auto;
  }

  .Menu_Heading p {
    font-size: 12px;
    margin-top: 0px;
  }
}
