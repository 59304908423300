.Coming_Soon_Text {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Heading_Container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.Heading_Container h1 {
  font-size: 46px;
  margin: auto;
  font-weight: 900;
  background: linear-gradient(270deg, #eb202c 53%, #0d5093 47%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Heading_Container p {
  color: #2e2e2e;
  font-size: 16px;
  margin: auto;
  margin-top: 10px;
  font-weight: 400;
  line-height: 1.6;
}
