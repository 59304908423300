.aos {
  opacity: 0;
  transform: translateY(18.4vh);
  transition: opacity 1s ease, transform 1s ease;
}

.aos.active {
  opacity: 1;
  transform: translateY(0);
}

.Body_Container_1 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
}

.Back_Section {
  width: 100%;
  height: 100%;
  display: flex;
}

.One {
  background-color: #dceaff;
}

.two {
  background-color: white;
}

.Three {
  background-color: #0d5093;
}

.Body {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: block;
  scroll-snap-align: start;
}

.Body_1 {
  width: 100%;
  height: 100vh;
  margin: auto;
  scroll-snap-align: start;
}

.Body_2 {
  display: none;
}

.Section {
  width: 85%;
  height: calc(100% - 90px);
  margin: auto;
  margin-top: 90px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Color_Change {
  width: 10px;
  background-color: transparent;
}

.Panel {
  width: 45%;
  height: auto;
  margin: auto;
}

.Panel h1 {
  color: #2e2e2e;
  font-size: 50px;
  font-weight: 900;
  line-height: 80px;
}

.Panel h1 span {
  color: white;
  font-size: 60px;
  font-weight: 900;
  background: linear-gradient(90deg, #0d5093 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: 0;
}

.Panel h1 span.active {
  animation: slideBackground 2s;
}

@keyframes slideBackground {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.underline1,
.underline2,
.underlinew,
.underline3 {
  position: relative;
  color: #2e2e2e !important;
  background: none !important;
}

.underlinew {
  color: #ffffff !important;
}

.underline1 {
  background-color: #fff !important;
}

.underline2 {
  background-color: #dceaff !important;
}

.underline3 {
  color: #0d5093 !important;
}

.underline1::after,
.underline2::after,
.underlinew::after,
.underline3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color: #2e2e2e;
  transform-origin: left center;
}

.underlinew::after {
  background-color: #ffffff !important;
}

.underline3::after {
  background-color: #0d5093 !important;
}

.underline1.active::after,
.underline2.active::after,
.underlinew.active::after,
.underline3.active::after {
  animation: under2 4s;
}

@keyframes under2 {
  0% {
    transform: scaleX(0);
  }

  50% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(1);
  }
}

p,
h3 {
  margin-top: 40px;
}

.Panel p {
  line-height: 70px;
}

.Panel h2 {
  color: white;
  font-size: 50px;
  font-weight: 900;
  line-height: 70px;
}

.Panel p a {
  color: #1f1f1f;
  font-size: 24px;
  font-weight: 600;
  text-decoration-line: underline;
}

.Panel h3 a {
  color: white;
  font-size: 24px;
  font-weight: 600;
  text-decoration-line: underline;
}

.Panel_B {
  width: 55%;
  height: auto;
  display: flex;
}

.Images {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Images img {
  width: 80%;
  height: auto;
  margin: auto;
  margin-right: 0;
  border-radius: 20px;
  object-fit: cover;
}

.Panel_C {
  display: none;
}

.Images_B {
  width: 100%;
  height: 80%;
  margin: auto;
  display: flex;
}

.Images_B img {
  width: 90%;
  height: 90%;
  margin: auto;
  margin-left: 0;
  border-radius: 20px;
}

.Bottom_Container {
  width: 100%;
  height: auto;
  display: flex;
  scroll-snap-align: normal;
  background-color: #dceaff;
}

.Content_About_Video_Analytics {
  width: 1920px;
  height: auto;
  margin: auto;
}

.Button_Section {
  width: 85%;
  height: auto;
  margin: auto;
}

.Top_Panel_Section {
  width: 100%;
  height: 160px;
  display: flex;
}

.Top_Panel_Section h1 {
  color: white;
  font-size: 80px;
  font-weight: 700;
  margin: auto;
  margin-bottom: 0px;
}

.ContentBlock {
  width: 74%;
  height: auto;
  display: flex;
  margin: auto;
}

.ContentBlock h1 {
  color: #2e2e2e;
  text-align: center;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 100px;
}

.OurBrand_Data {
  width: 100%;
  height: 39.89vh;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f2f2f2;
  margin-top: 9.22vh;
}

.OurBrand_Body {
  width: 100%;
  height: auto;
  margin: auto;
}

.OurBrand_Container {
  width: 85%;
  height: 300px;
  margin: auto;
  max-width: 1920px;
}

.OurBrand_Heading {
  width: 100%;
  height: 120px;
  display: flex;
}

.OurBrand_Heading h1 {
  color: #004182;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin: auto;
  margin-bottom: 20px;
}

.OurBrand_Logo_container {
  width: 100%;
  height: 160px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
}

.SliderButton {
  width: 80px;
  height: 100%;
  display: flex;
}

.SliderButton img {
  width: 35px;
  height: 35px;
  margin: auto;
}

.SliderButton button:hover {
  opacity: 0.5;
}

.AllOur_Brand_Logo_Container {
  width: calc(100% - 160px);
  height: 100%;
  overflow-x: auto;
  display: flex;
  gap: 55px;
  scroll-behavior: smooth;
}

.Our_Brand_Logo {
  min-width: 250px;
  height: 100%;
  display: flex;
}

.Our_Brand_Logo img {
  width: auto;
  height: auto;
  margin: auto;
}

.AllOur_Brand_Logo_Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Recognition_Block {
  width: 100%;
  height: 39.89vh;
  display: flex;
}

.Recognition_Body {
  width: 1920px;
  height: auto;
  margin: auto;
}

.Recognition {
  width: 85%;
  height: 270px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Recognition_Container {
  width: 500px;
  height: auto;
  margin: auto;
}

.Recognition_Container h1 {
  color: #000;
  font-size: 32px;
  font-weight: 900;
  margin: auto;
  margin-left: 0;
}

.Recognition_Container h2 {
  color: #565656;
  font-size: 24px;
  font-weight: 400;
  margin: auto;
}

.Recognition_Container h3 {
  color: #707199;
  font-size: 20px;
  font-weight: 400;
  margin: auto;
}

.Recognition_Details {
  width: calc(100% - 500px);
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Recognition_Logo_Container {
  width: 33%;
  height: 100%;
  margin: auto;
  display: flex;
}

.Recognition_Logo_Container img {
  width: auto;
  height: auto;
  margin: auto;
  margin-right: 0;
}

.OurPortFolio,
.OurPortfolio {
  width: 100%;
  height: 11vh;
  display: flex;
  background-color: #0d5093;
  cursor: default;
  z-index: 999;
  position: relative;
}

.OurPortFolio_Block {
  width: 1920px;
  height: 100%;
  margin: auto;
}

.Portfolio_data {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
}

.PortFolio_Panel {
  width: 50%;
  height: 100%;
  display: flex;
}

.PortFolio_Panel h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: auto;
  margin-left: 0%;
}

.PortFolio_Panel h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 30px;
  margin: auto;
  margin-right: 0%;
  background-color: #d61622;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}

.PortFolio_Panel img {
  width: auto;
  margin: auto;
  margin-left: 5px;
}

.OurPortfolio_Form {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
}

.Close_Button {
  width: 56px;
  height: 56px;
  position: relative;
  margin-left: auto;
  display: flex;
}

.Close_Button img {
  width: 30px;
  margin: auto;
  cursor: pointer;
}

.Form_Container {
  width: 50%;
  height: auto;
  margin: auto;
  border-radius: 20px;
  background-color: white;
  cursor: default;
}

.Form_Heading {
  width: 100%;
  height: auto;
  margin-top: 50px;
}

.Form_Heading h1 {
  text-align: center;
  color: #000;
  font-size: 30px;
  font-weight: 700;
  margin: auto;
}

.Form_Heading p {
  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin: auto;
}

.Form_InputField_Container {
  width: 86%;
  height: 50px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}

::placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 500;
}

.Form_InputField_Container input {
  width: 90%;
  height: 100%;
  padding: 1px 11px;
  font-size: 14px;
  color: #363636;
  font-weight: 600;
  display: inline-block;
  border: 1px solid #e2e2e2;
  cursor: text;
  border-radius: 6px;
}

input,
textarea:focus {
  outline: none;
}

.HomeMessage {
  width: 99%;
  height: 105%;
  padding: 15px 15px;
  font-size: 14px;
  color: #363636;
  font-weight: 800;
  display: inline-block;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  cursor: text;
  border-radius: 6px;
  resize: none;
}

.InputField_Panel {
  width: 47%;
  height: 100%;
  float: left;
}

.InputField_Panel_A {
  width: 47%;
  height: 100%;
  float: right;
}

.Form_InputField_Container_B {
  width: 86%;
  height: auto;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}

.Form_InputField_Container_A {
  width: 86%;
  height: auto;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
}

.Form_InputField_Container_A button {
  color: white;
  font-size: 14px;
  border: none;
  padding: 12px 40px;
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
  background-color: #004182;
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .Body_2 {
    display: block;
  }

  .Body_1 {
    display: none;
  }

  .Body_Container_1 {
    width: 100%;
    height: 100vh;
  }

  .Back_Section {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .Body {
    width: 100%;
    height: 100vh;
  }

  .Body_2 {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: block;
    scroll-snap-align: start;
  }

  .Section {
    width: 96%;
    height: calc(100% - 90px);
    margin-top: 90px;
  }

  .Panel h1,
  .Panel p {
    font-size: 35px;
    line-height: 50px;
  }

  .Panel h1 span {
    font-size: 35px;
  }

  .Panel h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .Content_About_Video_Analytics {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .Button_Section {
    width: 90%;
    height: auto;
    margin: auto;
  }

  .Top_Panel_Section {
    width: 100%;
    height: 160px;
    display: flex;
  }

  .Top_Panel_Section h1 {
    color: white;
    font-size: 80px;
    font-weight: 700;
    margin: auto;
    margin-bottom: 0px;
  }

  .ContentBlock {
    width: 100%;
    height: auto;
    display: flex;
    margin: auto;
  }

  /* .Recognition_Block {
        width: 100%;
        height: auto;
        display: flex;
    } */

  .Recognition_Body {
    width: 100%;
  }

  .Recognition {
    width: 94%;
    height: auto;
  }

  .Recognition_Container {
    width: 100%;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
  }

  .Recognition_Container h1 {
    color: #000;
    font-size: 25px;
    text-align: center;
  }

  .Recognition_Container h2 {
    font-size: 18px;
    text-align: center;
  }

  .Recognition_Container h3 {
    color: #707199;
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    text-align: center;
  }

  .Recognition_Details {
    width: 100%;
    height: auto;
  }

  .Recognition_Logo_Container {
    width: 100%;
    height: auto;
  }

  .Recognition_Logo_Container img {
    width: auto;
    height: 90px;
    margin: auto;
    margin-bottom: 20px;
    margin-right: auto;
  }

  .Images img {
    height: 70%;
  }

  .Images_B img {
    height: 70%;
  }
}

@media screen and (min-width: 260px) and (max-width: 600px) {
  p,
  h3,
  .Panel p {
    margin: auto;
    margin-top: 0px;
    line-height: 35px;
  }

  .aos {
    opacity: 0;
    transform: translateY(8.4vh);
    transition: opacity 1.8s ease, transform 1s ease;
  }

  .Top {
    width: 100%;
    height: 56px;
  }

  .Body_Container_1 {
    width: 100%;
    height: 100vh;
  }

  .Body_1 {
    display: none;
  }

  .Body_2 {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: block;
    scroll-snap-align: start;
  }

  .Section {
    width: 94%;
    height: 92%;
    margin-top: 56px;
  }

  .Panel {
    width: 100%;
    height: 50%;
    margin: auto;
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Panel h1 {
    text-align: center;
    font-size: 25px;
    margin: auto;
    line-height: 35px;
  }

  .Panel h1 span {
    font-size: 25px;
  }

  .Panel h2 {
    text-align: center;
    font-size: 25px;
    margin: auto;
    line-height: 35px;
  }

  .Panel h3 a {
    font-size: 15px;
  }

  .Panel p a {
    font-size: 15px;
    margin: auto;
  }

  .Panel_B {
    display: none;
  }

  .Panel_C {
    width: 100%;
    height: auto;
    display: flex;
  }

  .Images {
    width: 100%;
    height: 80%;
    margin-bottom: 0;
  }

  .Images img {
    width: 90%;
    height: 90%;
    margin: auto;
    margin-right: auto;
  }

  .Bottom_Container {
    width: 100%;
    height: auto;
  }

  .Content_About_Video_Analytics {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .Button_Section {
    width: 94%;
    height: auto;
  }

  .Top_Panel_Section {
    width: 100%;
  }

  .Top_Panel_Section h1 {
    font-size: 50px;
    font-weight: 500;
    margin: auto;
  }

  .ContentBlock {
    width: 94%;
  }

  .ContentBlock h1 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .underline1::after,
  .underline2::after,
  .underlinew::after {
    height: 2px;
  }

  .OurBrand_Data {
    width: 100%;
    height: 21.4vh;
  }

  .OurBrand_Body {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .OurBrand_Container {
    width: 94%;
    height: auto;
  }

  .OurBrand_Heading {
    width: 100%;
    height: auto;
    display: flex;
  }

  .OurBrand_Heading h1 {
    font-size: 15px;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .OurBrand_Logo_container {
    width: 100%;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  .SliderButton {
    width: 40px;
    height: 100%;
  }

  .SliderButton button {
    width: auto;
    height: auto;
    margin: 0;
  }

  .SliderButton img {
    width: 30px;
    height: 30px;
  }

  .AllOur_Brand_Logo_Container {
    width: 250px;
    height: auto;
    gap: 10px;
  }

  .Our_Brand_Logo {
    width: 100%;
    height: auto;
  }

  .Our_Brand_Logo img {
    width: 55%;
    height: 55%;
  }

  .Recognition_Block {
    width: 100%;
    height: 52vh;
    display: flex;
  }

  .Recognition_Body {
    width: 100%;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 40px;
    display: flex;
  }

  .Recognition {
    width: 100%;
    height: 100%;
    gap: 10px;
  }

  .Recognition_Container {
    width: 94%;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
  }

  .Recognition_Container h1 {
    color: #000;
    font-size: 25px;
    margin-bottom: 5px;
  }

  .Recognition_Container h2 {
    font-size: 18px;
  }

  .Recognition_Container h3 {
    color: #707199;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: auto;
    margin-top: 6px;
  }

  .Recognition_Details {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .Recognition_Logo_Container {
    flex: 0 0 auto;
    animation: scroll1 10s infinite linear;
    transform: translate(70%);
  }
  @keyframes scroll1 {
    to {
      transform: translateX(-270%);
    }
  }
  .Recognition_Logo_Container {
    width: 100%;
    height: auto;
  }

  .Recognition_Logo_Container img {
    width: auto;
    height: auto;
    margin: auto;

    margin-right: auto;
  }

  .OurPortFolio {
    width: 100%;
    height: 17.38vh;
  }
  .OurPortfolio {
    width: 100%;
    height: 50px;
  }

  .OurPortFolio_Block {
    width: 100%;
    height: 50px;
    margin: 0;
  }

  .Portfolio_data {
    width: 94%;
  }

  .PortFolio_Panel {
    width: 50%;
  }

  .PortFolio_Panel h1 {
    font-size: 12px;
    font-weight: 500;
  }

  .PortFolio_Panel h2 {
    font-size: 10px;
    padding: 6px 12px;
    border-radius: 4px;
  }

  .PortFolio_Panel img {
    width: 14px;
    margin: auto;
    margin-left: 0px;
  }

  .OurPortfolio_Form {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
  }

  .Close_Button {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
  }

  .Close_Button img {
    width: 25px;
    margin: auto;
    cursor: pointer;
  }

  .Form_Container {
    width: 90%;
    height: auto;
    margin: auto;
    border-radius: 20px;
    background-color: white;
  }

  .Form_Heading {
    width: 100%;
    margin-top: -30px;
  }

  .Form_Heading h1 {
    text-align: center;
    color: #000;
    font-size: 25px;
    font-weight: 700;
    margin: auto;
  }

  .Form_Heading p {
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    margin: 10px;

    line-height: normal;
  }

  .Form_InputField_Container {
    width: 86%;
    height: 125px;
    margin: auto;
    margin-bottom: 20px;
  }

  .Form_InputField_Container input {
    height: 90%;
  }

  .InputField_Panel {
    width: 100%;
    height: 50px;
    float: left;
    margin-bottom: 25px;
  }

  .HomeMessage {
    height: 100%;
  }

  .InputField_Panel_A {
    width: 100%;
    height: 50px;
    float: right;
    margin-bottom: 25px;
  }

  .Form_InputField_Container_A {
    width: 86%;
    height: auto;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
  }
}
