.background {
  align-items: center;
  display: flex;
  height: 50vh;
  justify-content: center;
  width: auto;
}

.background h1 {
  -webkit-text-fill-color: #0000;
  background: linear-gradient(180deg, #f60f00 27.01%, #0d5093 82.22%);
  background-clip: text;
  -webkit-background-clip: text;
  font-size: min(3.68vh, 70px);
}
