.section1 {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section1_Body_Section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Section_1_width {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 20px;
}

.section_1_top {
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_1_top_box {
  width: 380px;
  height: 75px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 0px;
  margin-bottom: 30px;
}

.section_1_top_box h1 {
  color: #2e2e2e;
  font-size: 58px;
  font-weight: 700;
  text-align: left;
  margin: auto;
}

.section_1_bottom {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_1_bottom h1 {
  color: #2e2e2e;
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  margin: auto;
  margin-top: 0px;
}

.section_1_bottom p {
  color: #565656;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: auto;
  margin-top: -50px;
}

@media screen and (min-width: 1240px) and (max-width: 1654px) {
  .section1 {
    width: 100%;
    height: 650px;
  }

  .section1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .Section_1_width {
    width: 85%;
    height: 100%;
    margin-top: 80px;
  }

  .section_1_top {
    width: 100%;
    height: 25%;
  }

  .section_1_top_box {
    width: 370px;
    height: 75px;
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .section_1_top_box h1 {
    color: #2e2e2e;
    font-size: 56px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_1_bottom {
    width: 100%;
    height: 75%;
  }

  .section_1_bottom h1 {
    color: #2e2e2e;
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-top: 0px;
  }

  .section_1_bottom p {
    color: #565656;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: -120px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1239px) {
  .section1 {
    width: 100%;
    height: 560px;
  }

  .section1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .Section_1_width {
    width: 85%;
    height: 100%;
    margin-top: 80px;
  }

  .section_1_top {
    width: 100%;
    height: 20%;
  }

  .section_1_top_box {
    width: 320px;
    height: 65px;
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .section_1_top_box h1 {
    color: #2e2e2e;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_1_bottom {
    width: 100%;
    height: 80%;
  }

  .section_1_bottom h1 {
    color: #2e2e2e;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-top: 0px;
  }

  .section_1_bottom p {
    color: #565656;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: -100px;
  }
}

@media screen and (min-width: 600px) and (max-width: 849px) {
  .section1 {
    width: 100%;
    height: 400px;
  }

  .section1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .Section_1_width {
    width: 90%;
    height: 100%;
    margin-top: 40px;
  }

  .section_1_top {
    width: 100%;
    height: 20%;
  }

  .section_1_top_box {
    width: 280px;
    height: 60px;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .section_1_top_box h1 {
    color: #2e2e2e;
    font-size: 44px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_1_bottom {
    width: 100%;
    height: 80%;
  }

  .section_1_bottom h1 {
    color: #2e2e2e;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-top: 0px;
  }

  .section_1_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    line-height: 20px;
  }
}

@media screen and (min-width: 450px) and (max-width: 599px) {
  .section1 {
    width: 100%;
    height: 650px;
  }

  .section1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .Section_1_width {
    width: 90%;
    height: 100%;
    margin-top: 40px;
  }

  .section_1_top {
    width: 100%;
    height: 20%;
  }

  .section_1_top_box {
    width: 240px;
    height: 55px;
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .section_1_top_box h1 {
    color: #2e2e2e;
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_1_bottom {
    width: 100%;
    height: 80%;
  }

  .section_1_bottom h1 {
    color: #2e2e2e;
    font-size: 21px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-top: 0px;
    line-height: 25px;
  }

  .section_1_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: -110px;
  }
}

@media screen and (min-width: 300px) and (max-width: 449px) {
  .section1 {
    width: 100%;
    height: 500px;
  }

  .section1_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .Section_1_width {
    width: 90%;
    height: 100%;
    margin-top: 20px;
  }

  .section_1_top {
    width: 100%;
    height: 18%;
  }

  .section_1_top_box {
    width: 190px;
    height: 44px;
    margin-left: 0px;
    margin-bottom: 25px;
  }

  .section_1_top_box h1 {
    color: #2e2e2e;
    font-size: 30px;
    font-weight: 700;
    margin: auto;
  }

  .section_1_bottom {
    width: 100%;
    height: 82%;
  }

  .section_1_bottom h1 {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin: auto;
    margin-top: 0px;
    line-height: 25px;
  }

  .section_1_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    margin: auto;
    line-height: 20px;
    margin-top: -20px;
  }
}

.Section_5 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_5_Body_Sections {
  width: 5020px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_5_widths {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_5_widths img {
  width: 100%;
  margin-top: min(9.22vh, 90px);
}

@media screen and (min-width: 300px) and (max-width: 1069px) {
  .Section_5 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_5_Body_Sections {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_5_widths {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.section_3 {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_3_Body_Section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_3_widths {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -20px;
}

.section_3_top {
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_3_top_box {
  width: 370px;
  height: 75px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 0px;
  margin-bottom: 30px;
}

.section_3_top_box h1 {
  color: #2e2e2e;
  font-size: 58px;
  font-weight: 700;
  text-align: left;
  margin: auto;
}

.section_3_bottom {
  width: 100%;
  height: 55%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_3_bottom p {
  color: #565656;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: auto;
  margin-top: 30px;
}

@media screen and (min-width: 1240px) and (max-width: 1654px) {
  .section_3 {
    width: 100%;
    height: 400px;
  }

  .section_3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_3_widths {
    width: 85%;
    height: 100%;
  }

  .section_3_top {
    width: 100%;
    height: 35%;
  }

  .section_3_top_box {
    width: 350px;
    height: 75px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .section_3_top_box h1 {
    color: #2e2e2e;
    font-size: 56px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_3_bottom {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_bottom p {
    color: #565656;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: 0px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1239px) {
  .section_3 {
    width: 100%;
    height: 400px;
  }

  .section_3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_3_widths {
    width: 85%;
    height: 100%;
  }

  .section_3_top {
    width: 100%;
    height: 100px;
  }

  .section_3_top_box {
    width: 320px;
    height: 65px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 0px;
    margin-bottom: 120px;
  }

  .section_3_top_box h1 {
    color: #2e2e2e;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_3_bottom {
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_bottom p {
    color: #2e2e2e;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    margin: auto;
    margin-top: 10px;
  }
}

@media screen and (min-width: 600px) and (max-width: 849px) {
  .section_3 {
    width: 100%;
    height: 400px;
  }

  .section_3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_3_widths {
    width: 90%;
    height: 100%;
  }

  .section_3_top {
    width: 100%;
    height: 23%;
  }

  .section_3_top_box {
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 0px;
    margin-bottom: -10px;
  }

  .section_3_top_box h1 {
    color: #2e2e2e;
    font-size: 44px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_3_bottom {
    width: 100%;
    height: 72%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: auto;
  }
}

@media screen and (min-width: 450px) and (max-width: 599px) {
  .section_3 {
    width: 100%;
    height: 500px;
  }

  .section_3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_3_widths {
    width: 90%;
    height: 100%;
    margin: auto;
  }

  .section_3_top {
    width: 100%;
    height: 23%;
  }

  .section_3_top_box {
    width: 230px;
    height: 55px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .section_3_top_box h1 {
    color: #2e2e2e;
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_3_bottom {
    width: 100%;
    height: 67%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: auto;
  }
}

@media screen and (min-width: 300px) and (max-width: 449px) {
  .section_3 {
    width: 100%;
    height: 600px;
  }

  .section_3_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_3_widths {
    width: 90%;
    height: 100%;
    margin: auto;
  }

  .section_3_top {
    width: 100%;
    height: 25%;
  }

  .section_3_top_box {
    width: 190px;
    height: 44px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .section_3_top_box h1 {
    color: #2e2e2e;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    margin: auto;
  }

  .section_3_bottom {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_bottom p {
    color: #565656;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    margin: auto;
    margin-top: 15px;
    line-height: 20px;
  }
}

.section_ {
  width: 100%;
  margin-top: 60px;
  height: 330px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #0d5093;
}

.section_2_Body_Section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_2_width_ {
  width: 100%;
  height: 68%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 60px;
  background-color: #0d5093;
}

.section_2_body {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: #c6c6c6 */
}

.section_2_body_parts1 {
  width: 25%;
  height: 100%;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: #ffcbcb */
}

.body_parts1_top {
  width: 100%;
  height: 50%;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: #b2b2b2 */
}

.body_parts1_top img {
  width: 95px;
  margin: auto;
  margin-top: 25px;
  margin-left: 40%;
}

.body_parts1_down {
  width: 100%;
  height: 40%;
  margin: auto;
  margin-top: 10px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.body_parts1_down h1 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  margin: auto;
  margin-left: 40%;
}

.body_parts1_down p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
  margin: auto;
  margin-left: 40%;
  opacity: 60%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .section_ {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_width_ {
    width: 100%;
    height: 68%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
    background-color: #0d5093;
  }

  .section_2_body {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: #c6c6c6 */
  }

  .section_2_body_parts1 {
    width: 25%;
    height: 100%;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: #ffcbcb */
  }

  .body_parts1_top {
    width: 100%;
    height: 50%;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: #b2b2b2 */
  }

  .body_parts1_top img {
    width: 70px;
    margin: auto;
    margin-top: 25px;
    margin-left: 36%;
  }

  .body_parts1_down {
    width: 100%;
    height: 40%;
    margin: auto;
    margin-top: 10px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .body_parts1_down h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    margin: auto;
    margin-left: 37%;
  }

  .body_parts1_down p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    margin: auto;
    margin-left: 37%;
    opacity: 60%;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .section_ {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_width_ {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: -15px;
    background-color: #0d5093;
  }

  .section_2_body {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_body_parts1 {
    width: 50%;
    height: 50%;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .body_parts1_top {
    width: 100%;
    height: 50%;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .body_parts1_top img {
    width: 70px;
    margin: auto;
    margin-top: 25px;
    margin-left: 28%;
  }

  .body_parts1_down {
    width: 100%;
    height: 50%;
    margin: auto;
    margin-top: 10px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .body_parts1_down h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    margin: auto;
    margin-left: 30%;
  }

  .body_parts1_down p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    margin: auto;
    margin-left: 32%;
    opacity: 60%;
  }
}

.one {
  padding: 0;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 220px;
  margin-bottom: 120px;
  list-style: none;
}

.four {
  display: none;
}

.one li {
  position: relative;
  width: auto;
  margin: auto;
  height: 33.33%;
  overflow: hidden;
}

.one::after {
  content: "";
  clear: both;
  display: block;
}

.two {
  padding: 0;
  width: 100%;
  margin: auto;
  margin-top: 100px;
  height: auto;
  list-style: none;
}

.two li {
  position: relative;
  width: auto;
  margin: auto;
  height: 33.33%;
  overflow: hidden;
}

.two::after {
  content: "";
  clear: both;
  display: block;
}

.three {
  padding: 0;
  width: 100%;
  margin: auto;
  margin-top: 360px;
  height: auto;
  list-style: none;
}

.three li {
  position: relative;
  width: auto;
  margin: auto;
  height: 33.33%;
  overflow: hidden;
}

.three::after {
  content: "";
  clear: both;
  display: block;
}

.col-6 {
  width: 1500px;
  margin: auto;
  display: flex;
}

.square {
  width: 440px;
  height: 540px;
  position: relative;
  margin: 40px;
  background-color: transparent;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content img {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
  border-radius: 16px;
  border: smooth;
  object-fit: cover;
  width: 100%;
}

@media screen and (min-width: 1440px) and (max-width: 1700px) {
  .four {
    display: none;
  }

  .one {
    padding: 0;
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 220px;
    margin-bottom: 120px;
    list-style: none;
  }

  .one li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .one::after {
    content: "";
    clear: both;
    display: block;
  }

  .two {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    height: auto;
    list-style: none;
  }

  .two li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .two::after {
    content: "";
    clear: both;
    display: block;
  }

  .three {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 360px;
    height: auto;
    list-style: none;
  }

  .three li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .three::after {
    content: "";
    clear: both;
    display: block;
  }

  .col-6 {
    width: 1200px;
    margin: auto;
    display: flex;
  }

  .square {
    width: 340px;
    height: 420px;
    position: relative;
    margin: 30px;
    background-color: transparent;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    border: smooth;
    object-fit: cover;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .four {
    display: none;
  }

  .one {
    padding: 0;
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 180px;
    margin-bottom: 80px;
    list-style: none;
  }

  .one li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .one::after {
    content: "";
    clear: both;
    display: block;
  }

  .two {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    height: auto;
    list-style: none;
  }

  .two li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .two::after {
    content: "";
    clear: both;
    display: block;
  }

  .three {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 260px;
    height: auto;
    list-style: none;
  }

  .three li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .three::after {
    content: "";
    clear: both;
    display: block;
  }

  .col-6 {
    width: 920px;
    margin: auto;
    display: flex;
  }

  .square {
    width: 250px;
    height: 310px;
    position: relative;
    margin: 30px;
    background-color: transparent;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    border: smooth;
    object-fit: cover;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .four {
    display: none;
  }

  .one {
    padding: 0;
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 140px;
    margin-bottom: 80px;
    list-style: none;
  }

  .one li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .one::after {
    content: "";
    clear: both;
    display: block;
  }

  .two {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 60px;
    height: auto;
    list-style: none;
  }

  .two li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .two::after {
    content: "";
    clear: both;
    display: block;
  }

  .three {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 170px;
    height: auto;
    list-style: none;
  }

  .three li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .three::after {
    content: "";
    clear: both;
    display: block;
  }

  .col-6 {
    width: 680px;
    margin: auto;
    display: flex;
  }

  .square {
    width: 184px;
    height: 224px;
    position: relative;
    margin: 20px;
    background-color: transparent;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    border: smooth;
    object-fit: cover;
    width: 100%;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .one {
    display: none;
  }

  .two {
    display: none;
  }

  .three {
    display: none;
  }

  .four {
    display: block;
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    height: auto;
    list-style: none;
  }

  .four li {
    position: relative;
    width: auto;
    margin: auto;
    height: 33.33%;
    overflow: hidden;
  }

  .col-6 {
    width: 290px;
    margin: auto;
  }

  .square {
    width: 250px;
    height: 310px;
    position: relative;
    margin: auto;
    margin: 20px;
    background-color: transparent;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content img {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
    border-radius: 16px;
    border: smooth;
    object-fit: cover;
    width: 100%;
  }
}
