body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: auto !important;

  overscroll-behavior-y: auto !important;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
button,
strong,
em,
mark,
code,
small,
del,
ins,
b,
i,
abbr,
blockquote {
  font-display: swap;
}
