/* video */
.Sec {
    width: 100%;
    height: 1026px;
    display: block;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #DCEAFF;
}

.mobile_section {
    display: none;
}

.Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #DCEAFF;
}

.Body_Section_parts {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.left_Body_Section_parts {
    width: 500px;
    height: 1026px;
    margin: auto;
    text-align: center;
    background-color: #DCEAFF;
}

.left_Body_Section_parts img {
    width: 240px;
    margin: auto;
    margin-top: 360px;
}

.left_Body_Section_parts p {
    font-size: 38px;
    font-weight: 700;
    color: #303030;
    margin: auto;
    margin-top: 70px;

}

.right_Body_Section_parts {
    width: 1100px;
    height: 1026px;
    margin: auto;
    margin-right: 0px;
    text-align: center;
    background-color: #a2a2a2;
}

.right_Body_Section_parts img {
    width: 1100px;
    margin: auto;
    margin-right: 0px;
    height: auto;
}

.Body_Section_video {
    width: 200px;
    height: 200px;
    position: relative;
    text-align: center;
    margin: auto;
    z-index: 1;
    margin-top: -160px;
    margin-right: 40px;
}

input[type="checkbox"] {
    width: 0;
    height: 0;
    margin-bottom: 60px;
    margin-right: 50px;
    /* margin: 60px; */
    border: 0 none;
    padding: 0;
    cursor: pointer;
}

input[type="checkbox"]:before {
    content: url('../../assets/image/play_icon.svg');
    display: block;
    width: 60px;
    height: 60px;
    cursor: pointer;
    overflow: hidden;
}

input[type="checkbox"]:checked:before {
    content: url('../../assets/image/pause_icon.svg');
}


.Body_Section_video p {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    line-height: 3;
}

.video {
    width: 1100px;
    height: 1026px;
    display: flex;
    margin: auto;
    margin-top: -1054px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.video iframe {
    width: 100%;
    height: 1000px;
    margin: auto;
    margin-right: 0px;
}

@media screen and (min-width:1300px) and (max-width:1599px) {

    /* video */
    .Sec {
        width: 100%;
        height: 854px;
        display: block;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mobile_section {
        display: none;
    }

    .Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #DCEAFF;
    }

    .Body_Section_parts {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .left_Body_Section_parts {
        width: 400px;
        height: 854px;
        margin: auto;
        text-align: center;
        background-color: #DCEAFF;
    }

    .left_Body_Section_parts img {
        width: 240px;
        margin: auto;
        margin-top: 320px;
    }

    .left_Body_Section_parts p {
        font-size: 34px;
        font-weight: 700;
        color: #303030;
        margin: auto;
        margin-top: 50px;

    }

    .right_Body_Section_parts {
        width: 900px;
        height: 854px;
        margin: auto;
        margin-right: 0px;
        text-align: center;
        background-color: #a2a2a2;
    }

    .right_Body_Section_parts img {
        width: 900px;
        margin: auto;
        margin-right: 0px;
        margin-top: 66px;
    }

    .Body_Section_video {
        width: 200px;
        height: 200px;
        position: relative;
        text-align: center;
        margin: auto;
        margin-top: -160px;
        margin-right: 40px;
    }

    input[type="checkbox"] {
        width: 0;
        height: 0;
        margin-bottom: 60px;
        margin-right: 50px;
        /* margin: 60px; */
        border: 0 none;
        padding: 0;
        cursor: pointer;
    }

    input[type="checkbox"]:before {
        content: url('../../assets/image/play_icon.svg');
        display: block;
        width: 60px;
        height: 60px;
        cursor: pointer;
        overflow: hidden;
    }

    input[type="checkbox"]:checked:before {
        content: url('../../assets/image/pause_icon.svg');
    }


    .Body_Section_video p {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        line-height: 3;
    }

    .video {
        width: 1100px;
        height: 854px;
        display: flex;
        margin: auto;
        margin-top: -858px;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .video iframe {
        width: 100%;
        height: 780px;
        margin: auto;
        margin-right: 0px;
    }
}

@media screen and (min-width:1000px) and (max-width:1299px) {

    /* video */
    .Sec {
        width: auto;
        height: auto;
        display: block;
        display: flex;
    }

    .mobile_section {
        display: none;
    }

    .Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
    }

    .Body_Section_parts {
        width: 100%;
        height: 100%;

    }

    .left_Body_Section_parts {
        width: 500px;
        height: 520px;
        margin: auto;
        text-align: center;

    }

    .left_Body_Section_parts img {
        width: 220px;
        margin: auto;
        margin-top: 180px;
    }

    .left_Body_Section_parts p {
        font-size: 34px;
        font-weight: 700;
        color: #303030;
        margin: auto;
        margin-top: 40px;

    }

    .right_Body_Section_parts {
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    .right_Body_Section_parts img {
        width: 100%;
        margin: auto;
    }

    .Body_Section_video {
        width: 200px;
        height: auto;
        margin-top: -160px;
        margin-right: 40px;
    }

    .Body_Section_video p {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        line-height: 3;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        margin-top: -884px;
    }

    .video iframe {
        width: 1000px;
        height: 900px;
        margin: auto;
    }
}

@media screen and (min-width:750px) and (max-width:999px) {

    /* video */
    .Sec {
        width: auto;
        height: auto;
        display: block;
        display: flex;
    }

    .mobile_section {
        display: none;
    }

    .Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
    }

    .Body_Section_parts {
        width: 100%;
        height: 100%;

    }

    .left_Body_Section_parts {
        width: 500px;
        height: 500px;
        margin: auto;
        text-align: center;

    }

    .left_Body_Section_parts img {
        width: 200px;
        margin: auto;
        margin-top: 170px;
    }

    .left_Body_Section_parts p {
        font-size: 34px;
        font-weight: 700;
        color: #303030;
        margin: auto;
        margin-top: 40px;

    }

    .right_Body_Section_parts {
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    .right_Body_Section_parts img {
        width: 100%;
        margin: auto;
    }

    .Body_Section_video {
        width: 200px;
        height: auto;
        margin-top: -160px;
        margin-right: 40px;
    }

    .Body_Section_video p {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        line-height: 3;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        margin-top: -680px;
    }

    .video iframe {
        width: 750px;
        height: 700px;
        margin: auto;
    }
}

@media screen and (min-width:550px) and (max-width:749px) {

    /* video */
    .Sec {
        width: auto;
        height: auto;
        display: block;
        display: flex;
    }

    .mobile_section {
        display: none;
    }

    .Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
    }

    .Body_Section_parts {
        width: 100%;
        height: 100%;

    }

    .left_Body_Section_parts {
        width: 450px;
        height: 420px;
        margin: auto;
        text-align: center;

    }

    .left_Body_Section_parts img {
        width: 180px;
        margin: auto;
        margin-top: 140px;
    }

    .left_Body_Section_parts p {
        font-size: 28px;
        font-weight: 700;
        color: #303030;
        margin: auto;
        margin-top: 30px;

    }

    .right_Body_Section_parts {
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    .right_Body_Section_parts img {
        width: 100%;
        margin: auto;
    }

    .Body_Section_video {
        width: 200px;
        height: auto;
        margin-top: -160px;
        margin-right: 40px;
    }

    .Body_Section_video p {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        line-height: 3;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        margin-top: -482px;
    }

    .video iframe {
        width: 550px;
        height: 500px;
        margin: auto;
    }
}

@media screen and (min-width:300px) and (max-width:549px) {

    /* video */
    .Sec {
        width: auto;
        height: auto;
        display: block;
        display: flex;
    }

    .mobile_section {
        display: none;
    }

    .Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
    }

    .Body_Section_parts {
        width: 100%;
        height: 100%;

    }

    .left_Body_Section_parts {
        width: 300px;
        height: 370px;
        margin: auto;
        text-align: center;

    }

    .left_Body_Section_parts img {
        width: 150px;
        margin: auto;
        margin-top: 135px;
    }

    .left_Body_Section_parts p {
        font-size: 22px;
        font-weight: 700;
        margin: auto;
        margin-top: 30px;
        color: #303030;

    }

    .right_Body_Section_parts {
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    .right_Body_Section_parts img {
        width: 100%;
        margin: auto;
    }

    .Body_Section_video {
        width: 200px;
        height: auto;
        margin-top: -130px;
        margin-right: 0px;
    }

    input[type="checkbox"] {
        width: 0;
        height: 0;
        margin-bottom: 60px;
        margin-right: 50px;
        /* margin: 60px; */
        border: 0 none;
        padding: 0;
        cursor: pointer;
    }

    input[type="checkbox"]:before {
        content: url('../../assets/image/play_icon.svg');
        display: block;
        width: 60px;
        height: 60px;
        cursor: pointer;
        overflow: hidden;
    }

    input[type="checkbox"]:checked:before {
        content: url('../../assets/image/pause_icon.svg');
    }

    .Body_Section_video p {
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        line-height: 3;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
        margin-top: -300px;
    }

    .video iframe {
        width: 350px;
        height: 300px;
        margin: auto;
    }
}

.section_2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2_width {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_2_content h1 {
    color: #303030;
    font-size: 32px;
    line-height: 1.8;
    font-weight: 700;
    text-align: justify;
    margin: auto;
    margin-top: 40px;
    margin-left: 0px;
}

.section_2_content p {
    color: #565656;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    text-align: justify;
}


/* facesense */
@media screen and (min-width:600px) and (max-width:1000px) {
    .section_2_width {
        width: 90%;
        height: 100%;
    }

    .section_2_content h1 {
        font-size: 28px;
        margin: auto;
        margin-top: 15px;
        margin-left: 0px;
    }

    .section_2_content p {
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
    }
}

@media screen and (min-width:300px) and (max-width:599px) {
    .section_2_width {
        width: 90%;
        height: 100%;
    }

    .section_2_content h1 {
        font-size: 26px;
        margin: auto;
        margin-top: 5px;
        margin-left: 0px;
    }

    .section_2_content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: justify;
    }
}

.section_3 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_width {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 60px;
}

.section_3_left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 20px;
}

.section_3_content_1 {
    width: 2%;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content_1_1 {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content_1_1 p {
    font-size: 18px;
    color: #0D5093;
    line-height: 2;
    margin: auto;
    margin-left: 0px;
}

.section_3_content_2 {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content_2_1 {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}

.section_3_content_2_1 p {
    font-size: 18px;
    color: #565656;
    line-height: 2;
    margin: auto;
    margin-left: 0px;
}

@media screen and (min-width:720px) and (max-width:900px) {

    /* points */
    .section_3 {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_width {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        margin-top: 60px;
    }

    .section_3_left {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 20px;
    }

    .section_3_content_1 {
        width: 2%;
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_1_1 {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_1_1 p {
        font-size: 17px;
        color: #0D5093;
        line-height: 2;
        margin: auto;
        margin-left: 0px;
    }

    .section_3_content_2 {
        width: 95%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_2_1 {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_2_1 p {
        font-size: 17px;
        color: #565656;
        line-height: 2;
        margin: auto;
        margin-left: 0px;
    }
}

@media screen and (min-width:300px) and (max-width:719px) {

    /* points */
    .section_3 {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_Body_Section {
        width: 1920px;
        height: 100%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_width {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        margin-top: 60px;
    }

    .section_3_left {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 20px;
    }

    .section_3_content_1 {
        width: 5%;
        height: auto;
        margin: 0px;
        margin-left: -10px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_1_1 {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_1_1 p {
        font-size: 16px;
        color: #0D5093;
        margin: auto;
        margin-top: 0px;
        margin-left: 0px;
    }

    .section_3_content_2 {
        width: 95%;
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_2_1 {
        width: 100%;
        height: 7%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .section_3_content_2_1 p {
        font-size: 16px;
        color: #565656;
        line-height: 30px;
        margin: auto;
        margin-left: 0px;
    }
}