section {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: min(9.22vh, 90px);
  display: flex;
}

.Text_Container {
  width: 1920px;
  height: 150px;
  margin: auto;
  display: flex;
}

.Left_Sec {
  width: 50%;
  height: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.Left_Sec h1 {
  color: #161616;
  font-size: 20px;
  text-align: center;
  margin: auto;
  font-weight: 600;
  text-transform: capitalize;
}

.Title {
  font-size: 40px !important;
}

.SubTitle {
  margin-top: -25px;
}

.Body_Container {
  width: 100%;
  max-width: 1920px;
  height: auto;
  margin: auto;
  display: flex;
}

.Top_Section_Content {
  width: 70%;
  height: auto;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.Top_Section_Content h1 {
  color: #161616;
  font-size: 30px;
  text-align: center;
  margin: auto;
  font-weight: 600;
}

.Top_Section_Content h1 span {
  color: #0f58a4;
}

.Facesense_Image {
  width: 500px;
  height: auto;
  margin: auto;
}

.Video_Container {
  width: 99.5%;
  height: 700px;
  margin-left: 5.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dceaff;
}

.Video_Container iframe {
  width: 60%;
  height: 100%;
  border: none;
}

#Section {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
}

.Body_Content {
  width: 85%;
  height: auto;
  margin: auto;
}

.Body_Content h2 {
  color: #161616;
  font-size: 40px;
  margin: auto;
  margin-top: 60px;
  font-weight: 600;
}

.Social {
  margin-top: 0 !important;
}

.Body_Content p {
  color: #787878;
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
}

.Body_Content_Second {
  width: 85%;
  height: auto;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.Content {
  width: 60%;
  height: 100%;
}

.Table {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
}

.BuletImage {
  width: 40px;
  height: 100%;
  display: flex;
}

.BuletImage img {
  width: auto;
  margin: auto;
  margin-left: 0;
  margin-top: 5px;
}

.Heading {
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
}

.Heading p {
  color: #787878;
  margin: auto;
  margin-left: 0;
  font-size: 20px;
  font-weight: 400;
}

.Images_Container {
  width: 40%;
  height: 100%;
  display: flex;
}

.Images_Container img {
  width: 80%;
  margin: auto;
  margin-right: 0;
}

/*Slider Section Start*/
.c_container {
  position: relative;
  margin: auto;
  text-align: center;
  width: 500px;
  border-radius: 15px;
  box-shadow: 0 8px 16px grey;
}

.c_container img {
  display: none;
  border-radius: 15px;
}

.c_container img.image-active {
  display: block;
}

.c-dot-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 1rem;
}

.dot {
  cursor: pointer;
  height: 13px;
  width: 13px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  transition: opacity 0.6s ease;
  opacity: 0.5;
}

/*Slider Section End*/

.Body_Content_Third {
  width: 85%;
  height: 500px;
  margin: auto;
}

.Body_Content_Third_Inner_Box {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
}

.Body_Content_Third_Inner_One {
  margin: auto;
  margin-top: 60px;
  width: 350px;
  height: 226px;
}

.Body_Content_Third_Slider {
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.Body_Content_Third_Slider button {
  margin: auto;
  margin-top: 20px;
  border: none;
  cursor: pointer;
}

.Body_Content_Forth {
  width: 85%;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
}

.Body_Content_Forth_Inner {
  width: auto;
  height: auto;
  display: flex;
}

.Container_One {
  width: 50%;
  height: auto;
  margin-right: 40px;
}

.Container_One_Inner {
  display: flex;
  width: 50%;
  height: auto;
}

.Container_Two {
  width: 30%;
  margin-right: 40px;
}

.Container_Three {
  width: 30%;
  margin-right: 40px;
}

.Container_Four {
  width: 40%;
}

.CloseButton {
  position: absolute;
  top: -15px;
  right: 25px;
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.Popup {
  width: 450px;
  max-width: 90%;
  height: 320px;
  position: fixed;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #fff;
  z-index: 200;
  animation: slide 0.5s;
}

@keyframes slide {
  0% {
    margin-top: -1000px;
  }

  100% {
    margin-top: 0px;
  }
}

.Popup span {
  display: inline-block;
}

.Popup img {
  width: 94px;
  height: 9 4px;
  margin: 8% auto;
  display: block;
  object-fit: contain;
}

.Popup h1 {
  color: #2e2e2e;
  margin: auto;
  margin-top: -50px;
  font-size: 30px;
  text-align: center;
}

.Popup h3 {
  margin-top: 5px;
}

.Popup h3 p {
  color: #565656;
  margin: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  text-align: center;
}

.Back_1 {
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  margin: auto;
}

.Back_2 {
  width: 100%;
  height: auto;
  margin: auto;
  background-color: #fff;
}

.Headline {
  padding: 5px 5px 5px 10px;
  border-radius: 20px 20px 0px 0px;
  background-color: #0f58a4;
  display: flex;
  height: 60px;
  justify-content: center;
}

.Headline h3 {
  color: white;
  text-align: center;
  margin: auto;
  line-height: 20px;
}

.Headline_Inner {
  width: 100%;
  height: auto;
  display: flex;
  margin: auto;
  margin-top: 10px;
  background-color: #f2f2f2;
}

.Headline_Inner p {
  font-size: 18px;
  font-weight: 400;
  color: #787878;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.Headline_Inner_Img img {
  margin: auto;
  align-items: center;
  justify-content: center;
}

.Headline_Inner_Img {
  height: auto;
  background-color: #f2f2f2;
  height: 41.25px;
  text-align: center;
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 10px;
}

.Body_Content_Forth_Inner_Buttons {
  display: flex;
  margin-top: 20px;
}

.Body_Content_Forth_Button_One {
  padding: 8px 10px;
  font-size: 17px;
  color: white;
  background-color: #0f58a4;
  border-radius: 5px;
  display: flex;
  border-color: #0f58a4;
  margin-top: 30px;
  margin-right: 20px;
  letter-spacing: 1px;
  align-items: center;
  border: none;
}
.Body_Content_Forth_Button_One1 {
  padding: 8px 10px;
  font-size: 17px;
  color: white;
  background-color: #0f58a4;
  border-radius: 5px;
  display: flex;
  border-color: #0f58a4;
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 20px;
  letter-spacing: 1px;
  align-items: center;
  border: none;
}

.Body_Content_Forth_Button_Two {
  padding: 8px 10px;
  font-size: 17px;
  border-radius: 5px;
  display: flex;
  margin-top: 30px;
  margin-right: 20px;
  outline: 1px solid #161616;
  letter-spacing: 1px;
  border: none;
  background-color: #fff;
}

.Body_Content_Fifth {
  width: 100%;
  height: auto;
  margin: auto;
  background-color: #f2f2f2;
}

.Body_Content_Fifth_Main {
  width: 85%;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
  background-color: #f2f2f2;
}

.Body_Fifth_Anime_Box_TB {
  width: 100px;
  height: 100px;
  margin: auto;
  background-color: #fff;
  position: relative;

  border-radius: 10px;
}

.Body_Fifth_Anime_Box_TB.active {
  animation-name: example1;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.Body_Fifth_Anime_Box_TB img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes example1 {
  from {
    top: -60px;
  }

  to {
    top: 30px;
  }
}

.Body_Fifth_Anime_Box_BT {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 30px;
  background-color: white;
  position: relative;
  border-radius: 10px;
}

.Body_Fifth_Anime_Box_BT.active {
  animation-name: example2;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.Body_Fifth_Anime_Box_BT img {
  margin: 0;
  position: absolute;
  /* top: 50%;
    left: 50%; */
  margin-top: 50px;
  margin-left: 50px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes example2 {
  from {
    top: 30px;
  }

  to {
    top: -60px;
  }
}

.Body_Content_Fifth_Main p {
  font-size: 50px;
}

.Body_Fifth_Heding_One {
  margin-left: 8.5%;
}

.Body_Fifth_Heding_Two {
  margin-left: 40%;
}

.Body_Fifth_Heding_Three {
  margin-left: 29%;
}

.Body_Fifth_Heding_Four {
  margin-left: 52%;
}

.Body_Content_Fifth_Inner {
  display: flex;
  height: 50px;
  color: #0f58a4;
}

.Fifth_Container_One {
  width: 10%;
  border-right: 3px solid;
  border-bottom: 3px solid;
}

.Fifth_Container_Two {
  width: 50%;
  border-right: 3px solid;
  border-bottom: 3px solid;
}

.Fifth_Container_Three {
  width: 40%;
  border-bottom: 3px solid;
}

.Fifth_Container_Four {
  width: 30%;
  border-right: 3px solid;
}

.Fifth_Container_Five {
  width: 60%;
  border-right: 3px solid;
}

.Fifth_Container_Six {
  width: 10%;
}

.Body_Fifth_Heding_One_Icon {
  margin-left: 120px;
}

.Body_Fifth_Heding_Two_Icon {
  margin-left: 43%;
}

.Body_Fifth_Heding_Three_Icon {
  margin-left: 27%;
}

.Body_Fifth_Heding_Four_Icon {
  margin-left: 54%;
}

.Body_Content_Sixth {
  width: 85%;
  height: auto;
  margin: auto;
  background-color: #fff;
}

.Body_Content_Sixth_Inner {
  width: auto;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
}

.Body_Content_Sixth_Heading p {
  font-size: 45px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

.Body_Content_Sixth_Main {
  padding: 0px 20px;
  margin: auto;
  gap: 10px;
  display: flex;
}

.Body_Content_Sixth_Main_One {
  width: 0%;
}

.Sixth_Inner_Content_One {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.Sixth_Inner_Content_One_Image {
  border-radius: 50px;
  width: 75px;
  height: 75px;
  display: flex;
}

.Sixth_Inner_Content_One_Image img {
  display: block;
  margin: auto;
  width: 100%;
}

.Sixth_Inner_Content_One p {
  margin-left: 20px;
  font-size: min(1.3vw, 25px);
  color: #000000;
}

.Body_Content_Sixth_Main_Two {
  width: 40%;
}

.Body_Content_Seven {
  width: 85%;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
}

.Body_Content_Seven_One {
  width: 50%;
}

.Body_Content_Seven_Two {
  width: 50%;
  padding: 0px 80px;
}

.Body_Content_Seven_One h2 {
  font-size: 40px;
  margin: auto;
}

.Body_Content_Seven_Two h2 span {
  color: #0f58a4;
}

.Body_Content_Seven button {
  padding: auto;
  display: flex;
  border-radius: 5px;
  border: none;
  background-color: #0f58a4;
  cursor: pointer;
}

.Body_Content_Seven span {
  padding: 8px;
  color: white;
}

.Body_Content_Seven img {
  padding: 5px 2px;
}

.Seven_Info_Box {
  display: flex;
}

.Seven_Info_Box span {
  color: grey;
  font-size: 16px;
}

.Seven_Info_Box_Location img {
  margin-top: -90px;
}

.fcf-form-group {
  margin-bottom: 0.8rem;
}

.fcf-form-control {
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.fcf-input-group_half {
  display: flex;
}

.fcf-input-group {
  display: flex;
}

.fcf-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

textarea.fcf-form-control {
  height: auto;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: lightgray;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: lightgray;
}

/* Model */
.uc_model {
  position: fixed;
  background: #000000bf;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uc_content {
  background: #fff;
  width: 900px;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}

.uc_header {
  height: 120px;
  padding: 20px 0px;
  position: relative;
}

.uc_header span {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.uc_heading_one {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

button {
  cursor: pointer;
}

.uc_heading_two {
  color: #000;
  text-align: center;
  font-size: 18px;
  margin-top: auto;
  font-weight: 500;
}

.uc_body {
  padding: 20px 10%;
}

.uc-form-group {
  margin-bottom: 40px;
}

.uc-form-control {
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.45rem;
}

textarea.uc-form-control {
  width: 100%;
  height: auto;
  padding: 0.375rem 0px;
}

input::-webkit-input-placeholder {
  color: #868686;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0.375rem 0px;
}

textarea::-webkit-input-placeholder {
  color: #868686;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0.375rem 10px;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #868686;
}

.uc-submit-button {
  margin-left: 40%;
  margin-bottom: 20px;
}

.uc-submit-button button {
  display: flex;
  width: 191px;
  height: 48px;
  padding: 11px 45px 11px 46px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0f58a4;
  border: none;
}

.uc-submit-button span {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-right: 10px;
}

.Pricing_Section {
  width: auto;
  height: auto;
  max-width: 1920px;
  margin: auto;
}

.Image_Container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: center;
  gap: 100px;
  margin-bottom: 60px;
}
.Section5_width img{
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .uc_content {
    width: 700px;
  }

  .Body_Content_Forth {
    width: 85%;
    height: auto;
    margin: auto;
    margin-bottom: 50px;
  }

  .Body_Content_Forth_Inner {
    display: block;
  }

  .Container_One {
    width: 100%;
  }

  .Container_One_Inner {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  .Container_Two {
    width: 25%;
    margin-right: 10px;
  }

  .Container_Three {
    width: 25%;
    margin-right: 10px;
  }

  .Container_Four {
    width: 50%;
  }

  .Headline h3 {
    color: white;
    text-align: center;
    font-size: 15px;
  }

  .Headline_Inner {
    padding: 5px;
    margin: 10px 0px;
    height: auto;
    background-color: #f2f2f2;
  }

  .Body_Content_Forth_Button_Two {
    padding: 8px 10px;
    font-size: 17px;
    border-radius: 5px;
    display: flex;
    margin-right: 0px;
    margin-left: 50px;
    letter-spacing: 1px;
  }

  .Body_Fifth_Heding_One_Icon {
    margin-left: 6%;
  }

  .Body_Fifth_Heding_Two_Icon {
    margin-left: 40%;
  }

  .Body_Fifth_Heding_Three_Icon {
    margin-left: 25%;
  }

  .Body_Fifth_Heding_Four_Icon {
    margin-left: 50%;
  }

  .Top_Section_Content h1 {
    font-size: 25px;
  }

  .Body_Content_Forth_Button_One1{
    margin-left: 94px;
  }
}

@media screen and (min-width: 275px) and (max-width: 900px) {
  .Sixth_Inner_Content_One_Image img {
    width: 75%;
    margin: auto;
  }

  .Sixth_Inner_Content_One {
    margin-bottom: 10px;
  }

  .Left_Sec {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .uc_header {
    width: auto;
    height: auto;
    padding: 10px 0px;
  }

  .uc_heading_one {
    margin-top: 0;
    width: 70%;
    margin: auto;
    font-size: 22px;
    line-height: normal;
  }

  .uc_model {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uc_content {
    width: 85%;
    height: auto;
  }

  .Field {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .uc-submit-button {
    margin-left: 0;
  }

  .uc_body {
    display: grid;
    grid-template-rows: auto;
  }

  .uc-form-group {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .uc_heading_two {
    line-height: normal;
    font-size: 14px;
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }

  textarea.uc-form-control {
    width: 100% !important;
    height: 110px !important;
  }

  .uc-form-control {
    width: 100%;
    font-size: 21px;
  }

  .uc-submit-button button {
    width: 180px;
    margin: auto;
  }

  .Left_Sec img {
    width: 200px;
  }

  section {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 80px;
    display: flex;
  }

  .Text_Container {
    height: auto;
  }

  .Body_Content_Sixth_Heading p {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .Title {
    font-size: 20px !important;
    margin-top: 0;
  }

  .SubTitle {
    font-size: 16px;
    margin-top: 10px;
    line-height: normal;
  }

  .Facesense_Image {
    margin: auto;
  }

  .Top_Section_Content {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
    display: flex;
  }

  .Top_Section_Content h1 {
    color: #161616;
    font-size: 16px;
    text-align: center;
    margin: auto auto;
    font-weight: 600;
    text-transform: capitalize;
  }

  .Video_Container {
    justify-content: center;
    width: auto;
    height: 500px;
    display: grid;
  }

  .Video_Container iframe {
    width: 65vw;
    height: 275px;
    margin: auto;
  }

  .Body_Content {
    width: 90%;
  }

  .Body_Content h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Body_Fifth_Anime_Box_BT {
    width: 75px;
    height: 75px;
  }

  .Body_Fifth_Anime_Box_BT img {
    width: 30px;
    height: 30px;
    left: 50%;
    bottom: 9%;
    margin-left: 0;
  }

  .Body_Fifth_Anime_Box_TB {
    width: 75px;
    height: 75px;
  }

  .Body_Fifth_Anime_Box_TB img {
    width: 30px;
    height: 30px;
    left: 50%;
    bottom: 9%;
    margin-left: 0;
  }

  .Body_Content p {
    color: #787878;
    text-align: justify;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6;
  }

  .Body_Content_Second {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 20px;
    display: block;
  }

  .Content {
    width: 100%;
    height: 100%;
  }

  .Table {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .BuletImage {
    width: 25px;
    height: 100%;
    display: flex;
  }

  .BuletImage img {
    width: auto;
    height: 15px;
    margin: auto;
    margin-left: 0;
    margin-top: 5px;
  }

  .Heading {
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
  }

  .Heading p {
    color: #787878;
    margin: auto;
    margin-left: 0;
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
  }

  .Images_Container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .Images_Container img {
    width: 100%;
    margin: 50px 0px;
  }

  /* Fourth Section */
  .Body_Content_Forth {
    width: 85%;
    height: auto;
    margin: auto;
    margin-bottom: 40px;
  }

  .Body_Content_Forth_Inner {
    display: block;
  }

  .Container_One {
    width: 100%;
  }

  .Container_One_Inner {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }

  .Container_Two {
    width: 25%;
    margin-right: 10px;
  }

  .Container_Three {
    width: 25%;
    margin-right: 10px;
  }

  .Container_Four {
    width: 50%;
  }

  .Headline h3 {
    color: white;
    text-align: center;
    font-size: 12px;
  }

  .Headline_Inner {
    width: auto;
    padding: 5px;
    margin: 10px 0px;
    height: auto;
    background-color: #f2f2f2;
  }

  .Headline_Inner p {
    font-size: 15px;
    font-weight: 400;
    color: #787878;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    line-height: 20px;
  }

  .Body_Content_Forth_Button_Two {
    padding: 8px 10px;
    font-size: 17px;
    border-radius: 5px;
    display: flex;
    margin-right: 0px;
    margin-left: 50px;
    letter-spacing: 1px;
  }

  /* Fifth Section */
  .Body_Content_Fifth_Main {
    width: 80%;
    height: auto;
    margin: auto;
    /* margin-bottom: 50px; */
    background-color: #f2f2f2;
  }

  .Body_Content_Fifth_Main p {
    font-size: 35px;
    margin-top: 10px;
  }

  .Body_Fifth_Heding_One {
    margin-left: 6%;
    font-size: 12px;
  }

  .Body_Fifth_Heding_Two {
    margin-left: 25%;
    font-size: 12px;
  }

  .Body_Fifth_Heding_Three {
    margin-left: 27%;
    font-size: 12px;
  }

  .Body_Fifth_Heding_Four {
    margin-left: 28%;
    font-size: 12px;
  }

  .Fifth_Container_Five {
    width: 55%;
    border-right: 3px solid;
  }

  .Fifth_Container_Six {
    width: 15%;
  }

  .Body_Fifth_Heding_One_Icon {
    margin-left: 4%;
  }

  .Body_Fifth_Heding_Two_Icon {
    margin-left: 25%;
  }

  .Body_Fifth_Heding_Three_Icon {
    margin-left: 20%;
  }

  .Body_Fifth_Heding_Four_Icon {
    margin-left: 20%;
  }

  /* Sixth Section */
  .Body_Content_Sixth {
    width: 90%;
    height: auto;
  }

  .Body_Content_Sixth_Main {
    padding: 30px 10px;
    display: grid;
    gap: 0px;
  }

  .Body_Content_Sixth_Main p {
    margin-top: 30px;
    line-height: normal;
    font-size: 15px;
  }

  .Body_Content_Sixth_Main_Two {
    width: 100%;
  }

  /* Seven Section */
  .Body_Content_Seven {
    display: block;
  }

  .Body_Content_Seven_One {
    width: 100%;
  }

  .Body_Content_Seven_One h2 {
    font-size: 35px;
    margin: auto;
  }

  .Body_Content_Seven_Two {
    width: 100%;
    padding: 20px 0px;
  }

  .Seven_Info_Box span {
    font-size: 14px;
  }

  .Body_Content_Seven_Two h2 {
    font-size: 20px;
    margin: auto;
    margin-bottom: 20px;
  }

  .Image_Container {
    margin-top: 0;
    gap: 50px;
    margin-bottom: 0;
  }

  .Image_Container img {
    width: 290px;
  }
  .Body_Content_Forth_Button_One {
    width: auto;
    height: 40px;
    padding: 4px 5px;
  }
  .Body_Content_Forth_Button_One strong {
    font-size: 14px;
    line-height: normal;
  }
  .Body_Content_Forth_Button_One img {
    width: 20px;
    height: 20px;
  }
  .Body_Content_Forth_Button_Two {
    width: auto;
    height: 40px;
    padding: 4px 5px;
  }
  .Body_Content_Forth_Button_Two strong {
    font-size: 14px;
    line-height: normal;
  }
  .Body_Content_Sixth_Inner {
    margin-bottom: 0px;
  }

  #Perimetrion_Button {
    margin-top: 70px;
  }
  .Body_Content_Forth_Inner_Buttons {
    padding-bottom: 30px !important;
  }


  .Body_Content_Forth_Button_One1 {
    width: auto;
    height: 40px;
    padding: 4px 5px;
    margin-left: 20px;
  }
  .Body_Content_Forth_Button_One1 strong {
    font-size: 14px;
    line-height: normal;
  }
  .Body_Content_Forth_Button_One1 img {
    width: 20px;
    height: 20px;
  }
  .Section5_width img{
    width:100%;
    height: auto;
  }

}
