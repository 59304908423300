.custom-file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.custom-file-label {
  width: 100%;
  font-size: 14px !important;
  color: gray;
}

.custom-file-icon {
  width: 20px;
  height: 20px;
  margin-left: auto;
  vertical-align: middle;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Form_container {
  width: 100%;
  height: 59.22vh;
  background-color: #dceaff;
  display: flex;
  align-items: center;
  z-index: 999;
  position: relative;
}

.Form {
  width: 85%;
  height: auto;
  display: flex;
  margin: auto;
  max-width: 1920px;
  justify-content: center;
}

.Left_section {
  width: 50%;
  height: 100%;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
}

.Left_section h1 {
  color: #2e2e2e;
  font-style: normal;
  font-weight: 900;
  line-height: 46px;
  width: 60%;
  align-self: center;
  justify-self: center;
  font-size: min(2vw, 40px);
  font-weight: 900;
}

.Left_section p {
  color: #2e2e2e;
  font-family: Arial;
  font-size: min(0.8vw, 16px);
  font-style: normal;
  line-height: 46px;
  width: 60%;
  align-self: center;
  justify-self: center;
  color: #787878;
  font-weight: 400;
  margin-top: 5px;
}

.Left_section h6 {
  width: 60%;
  color: #787878;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-self: center;
  margin-top: 35px;
}

.Left_section a {
  color: #0d5093;
}

.Right_section {
  width: 50%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: min(1.3vw, 25px);
  margin-top: 7vh;
}

.Input_Holder {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
}

.Input_Field,
.Input_Field2,
.Input_Field3,
.Message,
.Input_Field_1 {
  width: min(17.1875vw, 330px);
  height: min(3vh, 30px);
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  background: #fff;
  color: #303030;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input_Field2,
.Input_Field3,
.Left_Align {
  margin-left: auto;
}

.Message {
  width: 100% !important;
  height: min(18.4vh, 180px) !important;
  margin-left: 0 !important;
}

.Captcha_Block {
  width: 100%;
  height: auto;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: min(2.5vw, 48px);
  font-style: italic;
  font-weight: 900;
  line-height: 58px;
  letter-spacing: -1px;
  text-align: center;
  display: flex;
}

.Captcha_Block label {
  font-size: min(1.5vw, 30px);
}

.Re {
  cursor: pointer;
  margin-left: 10px;
}

.numbers {
  width: 120px;
  height: min(5.9vh, 58px);
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  border-radius: 4px;
}

.Input_Field_1 {
  width: 55px !important;
  height: min(1.5vw, 58px);
  margin-left: 10px;
  font-size: min(4.9vh, 58px) !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
}

.Captcha {
  width: auto;
  height: 40px;
}

.Form_Submit {
  width: 160px;
  height: 48px;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
  border-radius: 4px;
  background: #0d5093;
  border: #0d5093;
  margin: 0;
  margin-left: auto !important;
}

@media screen and (min-width: 600px) and (max-width: 1070px) {
  .Form {
    flex-direction: column;
  }

  .Left_section h1 {
    width: 160%;
    text-align: center;
    font-size: 26px;
  }

  .Left_section p {
    text-align: center;
    font-size: 12px;
    width: 100%;
  }

  .Input_Field2,
  .Left_Align {
    margin-left: auto;
  }

  .Left_Align {
    width: auto;
  }

  .Left_section h6 {
    width: 100%;
    text-align: center;
  }

  .Input_Holder {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .Right_section {
    width: 100%;
    margin-top: 50px;
    gap: 40px;
    justify-content: center;
  }

  .Input_Field,
  .Input_Field2 {
    width: 40%;
    height: 27px;
  }

  .Input_Field3 {
    width: 34vw;
    height: 27px;
  }

  .Form_container {
    height: auto;
    padding-top: 20px;
  }

  .Captcha_Block {
    margin-left: 0;
  }
}

@media screen and (min-width: 260px) and (max-width: 599px) {
  .Form {
    flex-direction: column;
  }

  .Left_section {
    width: auto;
    height: auto;
  }

  .Left_section h1 {
    width: auto;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
  }

  .Left_section h6 {
    width: auto;
    text-align: center;
    font-size: 12px;
    margin-top: 0px;
  }

  .Left_section p {
    font-size: 10px;
    width: 100%;
    line-height: 10px;
    text-align: center;
  }

  .Right_section {
    width: 100%;

    margin-top: 10px;
    gap: 10px;
    justify-content: center;
  }

  .Input_Holder {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .Input_Field,
  .Input_Field2,
  .Input_Field3 {
    width: auto;
    height: 10px;
    margin-left: 0;
  }

  .Left_Align {
    margin-left: 0;
  }

  .Input_Field_1 {
    height: 1.9vh;
  }

  .Form_container {
    height: auto;
    padding-top: 20px;
  }

  .Form_Submit {
    margin-left: 0 !important;
  }

  .Captcha_Block {
    font-size: 30px;
    letter-spacing: 1px;

    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .Captcha_Block label {
    font-size: 20px;
  }

  .numbers {
    width: 85px;
  }
  .Re {
    margin-left: 0;
  }

  .Message {
    height: 100px !important;
  }

  .Form_Submit {
    width: 100px;
    height: 40px;
  }
}
