.Form_Container_Section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 90px;
}

.Form_Container_width {
  width: 1920px;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 0px;
}

.Form_Container_parts {
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 120px;
}

.Form_Header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Form_Header h1 {
  font-size: 32px;
  font-weight: 700;
  margin: auto;
  margin-left: 20px;
}

.Form_Block {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.PartnerForm {
  width: 100%;
  height: 90%;
  margin: auto;
  margin-top: 40px;
}

.InputField_area_1 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
}

.InputField_area_1 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_1 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 120px;
  margin-top: 40px;
}

.InputField_box_1 {
  width: 33.5%;
  height: 50%;
  margin: auto;
  margin-right: 20px;
}

span {
  color: red;
}

.InputField_area_2 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_2 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_2 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 120px;
  margin-top: 40px;
}

.InputField_box_2 {
  width: 69.5%;
  height: 50%;
  margin: auto;
  margin-right: 20px;
}

.InputField_area_3 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_3 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_3 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 190px;
  margin-top: 40px;
}

.InputField_area_4 {
  width: 1000px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.InputField_area_4 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_4 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 176px;
  margin-top: 40px;
}

.InputField_box_3 {
  width: 69%;
  height: 28%;
  margin: auto;
  margin-right: 20px;
}

.InputField_area_5,
.InputField_area_5_1 {
  width: 740px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 260px;
}

.InputField_area_5_1 {
  margin-left: 20px;
  width: 830px;
}

.InputField_box_4 {
  width: 43%;
  height: 25%;
  margin: auto;
  margin: auto;
}

.InputField_box_5 {
  width: 43%;
  height: 25%;
  margin: auto;
  margin: auto;
}

.InputField_area_6 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_6 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_6 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 90px;
  margin-top: 40px;
}

.InputField_area_7 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_7 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_7 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 186px;
  margin-top: 40px;
}

.InputField_area_8 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_8 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_8 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 30px;
  margin-top: 40px;
}

.InputField_area_9 {
  width: 1000px;
  height: 100px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.InputField_area_9 p {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 20px;
  margin-top: 40px;
}

.InputField_area_9 a {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  margin-left: 24px;
  margin-top: 40px;
}

.form-input {
  padding: 15px 20px;
  width: 100%;
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: transparent;
}

.PartnerForm input {
  background-color: #ffffff;
}

::placeholder {
  color: #a19f9f;
  text-align: start;
}

textarea {
  font-size: 18px;
  font-style: normal;
  width: 100%;
  height: 130px;
  padding: 15px 20px;
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: transparent;
  resize: none;
  background-color: #ffffff;
  font-family: "Lato", sans-serif;
}

.button {
  width: 100%;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Button1 {
  width: 50%;
  height: 170px;
  text-align: end;
  margin: auto;
  margin-top: 60px;
}

.Button1 button {
  color: white;
  padding: 8px 38px;
  border-radius: 8px;
  font-size: 18px;
  background-color: #0d5093;
  margin: auto;
  margin-right: 40px;
  margin-top: 30px;
  border: none;
  cursor: pointer;
}

.SucessMsg {
  width: 220px;
  height: auto;
  margin: auto;
  margin-right: 0px;
}

.SucessMsg p {
  color: #0d5093;
  text-align: center;
}

@media screen and (min-width: 800px) and (max-width: 1240px) {
  .Form_Container_Section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 90px;
  }

  .Form_Container_width {
    width: 1920px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 0px;
  }

  .Form_Container_parts {
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: 40px;
  }

  .Form_Header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Form_Header h1 {
    font-size: 28px;
    font-weight: 700;
    margin: auto;
    margin-left: 20px;
  }

  .Form_Block {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .PartnerForm {
    width: 100%;
    height: 90%;
    margin: auto;
    margin-top: 40px;
  }

  .InputField_area_1 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .InputField_area_1 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_1 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 120px;
    margin-top: 40px;
  }

  .InputField_box_1 {
    width: 25.5%;
    height: 50%;
    margin: auto;
    margin-right: 20px;
  }

  .InputField_area_2 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_2 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_2 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 120px;
    margin-top: 40px;
  }

  .InputField_box_2 {
    width: 55.5%;
    height: 50%;
    margin: auto;
    margin-right: 19px;
  }

  .InputField_area_3 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_3 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_3 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 190px;
    margin-top: 40px;
  }

  .InputField_area_4 {
    width: 700px;
    height: 200px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .InputField_area_4 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 15px;
  }

  .InputField_area_4 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 176px;
    margin-top: 15px;
  }

  .InputField_box_3 {
    width: 55.5%;
    height: 50%;
    margin: auto;
    margin-right: 19px;
  }

  .InputField_area_5 {
    width: 420px;
    height: 200px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 283px;
  }

  .InputField_box_4 {
    width: 42.5%;
    height: 25%;
    margin: auto;
    margin-right: 20px;
  }

  .InputField_box_5 {
    width: 42.5%;
    height: 25%;
    margin: auto;
    margin-right: 20px;
  }

  .InputField_area_5_1 {
    margin-left: 40px;
    width: 510px;
  }

  .InputField_area_6 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_6 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_6 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 90px;
    margin-top: 40px;
  }

  .InputField_area_7 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_7 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_7 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 186px;
    margin-top: 40px;
  }

  .InputField_area_8 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_8 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_8 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 30px;
    margin-top: 40px;
  }

  .InputField_area_9 {
    width: 700px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_9 p {
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    margin-left: 20px;
    margin-top: 40px;
  }

  .InputField_area_9 a {
    font-size: 18px;
    font-weight: 700;
    margin: auto;
    margin-left: 24px;
    margin-top: 40px;
  }

  .PartnerForm input {
    background-color: #ffffff;
  }

  ::placeholder {
    color: #a19f9f;
    text-align: start;
    font-size: 16px;
  }

  textarea {
    font-size: 18px;
    font-style: normal;
    width: 100%;
    height: 130px;
    padding: 15px 20px;
    display: inline-block;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: transparent;
    resize: none;
    background-color: #ffffff;
    font-family: "Lato", sans-serif;
  }

  .button {
    width: 100%;
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Button1 {
    width: 50%;
    height: 170px;
    text-align: end;
    margin: auto;
    margin-top: 60px;
  }

  .Button1 button {
    color: white;
    padding: 8px 38px;
    border-radius: 8px;
    font-size: 18px;
    background-color: #0d5093;
    margin: auto;
    margin-right: 40px;
    margin-top: 30px;
    border: none;
    cursor: pointer;
  }

  .SucessMsg {
    width: 220px;
    height: auto;
    margin: auto;
    margin-right: 0px;
  }

  .SucessMsg p {
    color: #0d5093;
    text-align: center;
  }
}

@media screen and (min-width: 300px) and (max-width: 799px) {
  .Form_Container_Section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .Form_Container_width {
    width: 1920px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 0px;
  }

  .Form_Container_parts {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .Form_Header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Form_Header h1 {
    font-size: 22px;
    font-weight: 700;
    margin: auto;
    margin-left: -10px;
  }

  .Form_Block {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .PartnerForm {
    width: 100%;
    height: 90%;
    margin: auto;
    margin-top: 40px;
  }

  .InputField_area_1 {
    width: 340px;
    height: 120px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_1 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
    margin-top: 0px;
  }

  .InputField_area_1 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 80px;
    margin-top: 27px;
  }

  .InputField_box_1 {
    width: 90%;
    height: 50%;
    margin: 0;
  }

  .InputField_area_2 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_2 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
    margin-top: 40px;
  }

  .InputField_area_2 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 80px;
    margin-top: 60px;
  }

  .InputField_box_2 {
    width: 90%;
    height: 50%;
    margin: 0;
  }

  .InputField_area_3 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_3 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
    margin-top: 40px;
  }

  .InputField_area_3 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 140px;
    margin-top: 60px;
  }

  .InputField_area_4 {
    width: 340px;
    height: 120px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .InputField_area_4 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
    margin-top: 10px;
  }

  .InputField_area_4 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 126px;
    margin-top: 40px;
  }

  .InputField_box_3 {
    width: 90%;
    height: 50%;
    margin: 0;
  }

  .InputField_area_5 {
    width: 305px;
    height: 240px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;

    margin-top: 55px;
  }

  .InputField_area_5_1 {
    height: 140px;
    margin-left: 0;
    padding-bottom: 10px;
    justify-content: flex-start;
  }

  .InputField_box_4 {
    width: 100%;
    height: 25%;
    margin: auto;
  }

  .InputField_box_5 {
    width: 100%;
    height: 25%;
    margin: auto;
  }

  .InputField_area_6 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_6 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
  }

  .InputField_area_6 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 50px;
    margin-top: 60px;
  }

  .InputField_area_7 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_7 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
  }

  .InputField_area_7 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 120px;
    margin-top: 60px;
  }

  .InputField_area_8 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_8 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
  }

  .InputField_area_8 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 30px;
    margin-top: 65px;
  }

  .InputField_area_9 {
    width: 340px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .InputField_area_9 p {
    font-size: 14px;
    font-weight: 700;
    margin: auto;
    margin-left: 0px;
  }

  .InputField_area_9 a {
    font-size: 1px;
    font-weight: 700;
    margin: auto;
    margin-left: 24px;
    margin-top: 65px;
  }

  .PartnerForm input {
    background-color: #ffffff;
  }

  ::placeholder {
    color: #a19f9f;
    text-align: start;
    font-size: 16px;
  }

  textarea {
    font-size: 18px;
    font-style: normal;
    width: 100%;
    height: 130px;
    padding: 15px 20px;
    display: inline-block;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: transparent;
    resize: none;
    background-color: #ffffff;
    font-family: "Lato", sans-serif;
  }

  .button {
    width: 100%;
    height: 150px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Button1 {
    width: 50%;
    height: 100px;
    text-align: end;
    margin: auto;
  }

  .Button1 button {
    color: white;
    padding: 8px 38px;
    border-radius: 8px;
    font-size: 18px;
    background-color: #0d5093;
    margin: auto;
    margin-right: 40px;
    margin-top: 30px;
    border: none;
    cursor: pointer;
  }

  .SucessMsg {
    width: 220px;
    height: auto;
    margin: auto;
    margin-right: 0px;
  }

  .SucessMsg p {
    color: #0d5093;
    text-align: center;
  }
}
