.Footer_Head {
  width: 100%;
  height: auto;
}

.Footer1 {
  width: 100%;
  height: 5vh;
  background: #004182;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_1 {
  width: auto;
  height: auto;
  margin: auto;
}

.Footer1 h1 {
  padding: 15px 20px;
  color: #fff;
  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Footer_Links {
  width: 100%;
  height: 5vh;
  background-color: #0d5093;
  cursor: default !important;
}

.Footer_components {
  margin: auto;
  max-width: 1920px;
  height: 5vh;
  margin-bottom: 5px;
}

.Our_Sites {
  width: 85%;
  height: auto;
  margin: auto;
  display: flex;
  justify-self: flex-start;
  margin-left: 120px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Our_Sites p {
  width: 151px;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-top: 5px;
}

.Our_Sites a {
  width: 151px;
  text-decoration: underline;
  border-right: 0.1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer !important;
  color: #fff;
  background-color: #0d5093;
}

.Site_Button1 {
  border-right: 0.1px solid #0d5093 !important;
}

.Our_Sites img {
  width: auto;
  height: 39px;
  margin-bottom: auto;
  margin-left: auto;
}

.products {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #0d5093;
  padding-top: 5px;
}

.Products_Link_Section {
  width: 87%;
  margin: auto;
  max-width: 1920px;
  display: flex;
  cursor: default !important;
}

.ProductLinks {
  width: min(43.5vw, 837px);
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: #004182;
}

.ProductLinks a {
  color: #fff;
  margin-left: 70px;
  font-family: Arial;
  font-size: min(0.8vw, 16px);
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
  cursor: pointer !important;
}

.Products_1,
.Products_2,
.Products_3 {
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
}

.Reachus {
  width: min(28vw, 539px);
  height: 250px;
  background-color: #004182;
  margin-left: auto;
  margin-right: 1%;
}

.Reachus h1 {
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 20px;
  margin-top: 0;
}

.Subscribe {
  width: min(20vw, 391px);
  height: 28px;
  margin-left: 15px;
  margin-top: -20px;
  padding: 15px 20px;
  background-color: #0d5093;
  border: #004182;
  color: rgba(255, 255, 255, 0.2);
  font-family: Arial;
  font-size: min(1vw, 20px);
  font-style: normal;
  font-weight: 400;
}

.SubButton {
  position: relative;
  width: 130px;
  height: 36px;
  margin-top: -10px;
  margin-left: -155px;
  background: #004182;
  border: #004182;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.Contact {
  width: auto;
  height: auto;
  display: grid;
  gap: 12px;
  margin-top: 25px;
  margin-left: 20px;
}

.Contact a {
  text-decoration: none;
  color: #fff;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer !important;
}

.text {
  display: flex;
  gap: 10px;
}

label {
  cursor: pointer;
}

.Social {
  width: auto;
  height: 35px;
  display: flex;
  gap: min(3.1vw, 60px);
  margin-top: -25px;
  margin-left: 20px;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 601px) and (max-width: 1070px) {
  .Footer1 {
    height: 50px;
  }

  .Footer1 h1 {
    font-size: 25px;
  }

  .Our_Sites {
    margin-left: 60px;
  }

  .Our_Sites p {
    font-size: 18px;
  }

  .Our_Sites a {
    width: 120px;
    height: 30px;
    font-size: 12px;
  }

  .footer_1 {
    width: 100vw;
    height: 100vh;
    background-color: #0d5093;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Products_Link_Section {
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  .ProductLinks {
    width: 93vw;
    height: 270px;
    justify-content: space-around;
  }

  .ProductLinks a {
    font-size: 14px;
  }

  .Reachus {
    width: 61.5vw;
    margin: auto !important;
  }

  .Subscribe {
    width: 50vw;
    font-size: 18px;
  }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  .Footer1 {
    height: 50px;
  }

  .Footer1 h1 {
    font-size: 20px;
  }

  .Footer_Links {
    width: 100%;
    height: 20vh;
  }

  .Footer_components {
    height: 100%;
    margin-bottom: 0;
    display: flex;
  }

  .Our_Sites {
    height: 90%;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 20px;
    justify-content: space-around;
    align-items: center;
  }

  .Our_Sites p {
    font-size: 16px;
    line-height: 15px;
    margin-top: 0;
    text-align: center;
    border-right: none;
    margin-bottom: 10px;
  }

  .Our_Sites a {
    width: 130px;
    height: 20px;
    font-size: 12px;

    border-right: none;
  }

  .Our_Sites img {
    width: 70px;
    height: auto;
    margin-left: 0;
  }

  .footer_1 {
    width: 100vw;
    height: 90.78vh;
    background-color: #0d5093;
    padding-top: 9.22vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .Products_Link_Section {
    height: 80%;
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  .ProductLinks {
    padding: 20px;
    width: 83vw;
    height: auto;
    justify-content: space-around;
  }

  .ProductLinks a {
    font-size: 10px;
    margin-left: 0;
    line-height: normal;
  }

  .Reachus {
    width: 91.5vw;
    height: auto;
    margin: auto !important;
  }

  .Reachus h1 {
    font-size: 10px;
  }

  .Subscribe {
    width: 70vw;
    height: 10px;
    font-size: 10px;
  }

  .SubButton {
    width: 60px;
    height: 20px;
    font-size: 10px;
    margin-left: -80px;
    position: relative;
  }

  .Social img {
    width: 10px;
    height: 10px;
  }

  .Social {
    margin-left: 10px;
  }

  .products {
    height: 60vh;
    padding-top: 10px;
  }

  .Contact {
    gap: 6px;
    margin-top: 10px;
  }

  .Contact a {
    font-size: 10px;
  }
}

@media screen and (min-width: 260px) and (max-width: 359px) {
  .Footer1 {
    height: 50px;
    margin-top: min(90px, 9.22vh);
  }

  .Footer1 h1 {
    font-size: 18px;
  }

  .Our_Sites {
    width: 90%;
    gap: 7px;
    margin-left: 10px;
  }

  .Our_Sites p {
    font-size: 10px;
    line-height: 15px;
    margin-top: 0;
    border-right: none;
  }

  .Our_Sites a {
    width: 70px;
    height: 25px;
    font-size: 8px;
    border-right: none;
  }

  .Our_Sites img {
    width: 30px;
    height: auto;
  }

  .footer_1 {
    width: 100vw;
    height: 100vh;
    background-color: #0d5093;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .products {
    margin-top: 20px;
  }

  .Products_Link_Section {
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  .ProductLinks {
    width: 95vw;
    height: auto;
    justify-content: center;
  }

  .ProductLinks a {
    font-size: 8px;
    margin-left: 0;
  }

  .Reachus {
    width: 81.5vw;
    height: auto;
    margin: auto !important;
  }

  .Subscribe {
    width: 35vw;
    font-size: 18px;
  }

  .SubButton {
    width: 50px;
    font-size: 8px;
    margin-left: -55px;
  }

  .Social img {
    width: 12px;
    height: 12px;
  }

  .Social {
    margin-top: 5px;
    margin-left: 10px;
  }

  .Contact a {
    font-size: 8px;
  }
}
