.custom-file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.custom-file-label {
  width: 100%;
  font-size: 14px !important;
  color: gray;
}

.custom-file-icon {
  width: 20px;
  height: 20px;
  margin-left: auto;
  vertical-align: middle;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Formcontainer {
  width: 100%;
  height: 65vh;
  background-color: #dceaff;
  display: flex;
  align-items: center;
}

.Form {
  width: auto;
  height: auto;
  display: flex;
  margin: auto;
  max-width: 1920px;
  justify-content: center;
}

.Left_Section {
  width: 45vw;
  height: 100%;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
}

.Left_Section h1 {
  color: #2e2e2e;
  font-style: normal;
  font-weight: 900;
  line-height: 46px;
  width: 60%;
  font-size: min(2vw, 40px);
  font-weight: 900;
  margin-top: 0;
}

.Left_Section p {
  color: #2e2e2e;
  font-family: Arial;
  font-size: min(0.8vw, 16px);
  font-style: normal;
  line-height: 46px;
  width: 60%;
  align-self: center;
  justify-self: center;
  color: #787878;
  font-weight: 400;
  margin-top: 5px;
}

.Left_Section h6 {
  width: 60%;
  color: #787878;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-self: center;
  margin-top: 35px;
}

.Left_Section a {
  color: #0d5093;
}

.Rightsection {
  width: 45vw;
  height: auto;
  margin: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: min(1.3vw, 25px);
  margin-top: 10vh;
}

.Input_Field,
.Message,
.Input_Field_1 {
  width: min(17.1875vw, 330px);
  height: min(3vh, 30px);
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  background: #fff;
  color: #303030;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Message {
  width: min(40.1vw, 770px) !important;
  height: min(18.4vh, 180px) !important;
}

.Captcha_Block {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: min(2.5vw, 48px);
  font-style: italic;
  font-weight: 900;
  line-height: 58px;
  letter-spacing: -1px;
  text-align: center;
  display: flex;
}

.Captcha_Block label {
  font-size: min(1.5vw, 30px);
}

.Re {
  cursor: pointer;
  margin-left: 10px;
}

.numbers {
  width: 120px;
  height: min(5.9vh, 58px);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  border-radius: 4px;
}

.Input_Field_1 {
  width: 55px !important;
  height: min(1.5vw, 58px);
  margin-left: 10px;
  font-size: min(4.9vh, 58px) !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
}

.Captcha {
  width: auto;
  height: 40px;
}

.FormSubmit {
  width: 160px;
  height: 48px;
  color: #fff;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-shrink: 0;
  border-radius: 4px;
  background: #0d5093;
  border: #0d5093;
  margin: auto;
}

@media screen and (min-width: 600px) and (max-width: 1070px) {
  .Form {
    flex-direction: column;
  }

  .Left_Section h1 {
    width: 160%;
    text-align: center;
    font-size: 26px;
  }

  .Left_Section p {
    font-size: 12px;
  }

  .Left_Section h6 {
    width: 100%;
    text-align: center;
  }

  .Rightsection {
    width: 100%;
    margin-top: 50px;
    gap: 40px;
    justify-content: center;
  }

  .Input_Field {
    width: 36.5vw;
    height: 27px;
  }

  .Message {
    width: 83vw !important;
    height: 150px !important;
  }

  .FormSubmit {
    position: relative;
    margin: auto;
    margin-top: 10px;
  }

  .Formcontainer {
    height: 100vh;
  }

  .Captcha_Block {
    margin-left: 140px;
  }
}

@media screen and (min-width: 260px) and (max-width: 599px) {
  .Form {
    flex-direction: column;
  }

  .Left_Section {
    width: 100%;
    height: auto;
  }

  .Left_Section h1 {
    width: auto;

    text-align: center;
    font-size: 18px;
    line-height: 20px;
  }

  .Left_Section h6 {
    width: auto;
    text-align: center;
    font-size: 12px;
    margin-top: 0px;
  }

  .Left_Section p {
    font-size: 10px;
    width: 100%;
    line-height: 10px;
    text-align: center;
  }

  .Rightsection {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    gap: 10px;
    justify-content: center;
  }

  .Input_Field {
    width: 36.5vw;
    height: 17px;
  }

  .Input_Field_1 {
    height: 1.7vh;
  }

  .Message {
    width: 95vw !important;
    height: 150px !important;
  }

  .FormSubmit {
    position: relative;
    margin: auto;
    margin-top: 10px;
  }

  .Formcontainer {
    height: 100vh;
  }

  .Captcha_Block {
    font-size: 30px;
    letter-spacing: 1px;
  }

  .Captcha_Block label {
    font-size: 20px;
  }
}
