input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Form_container {
    z-index: 100;
    width: 100%;
    height: 59.22vh;
    background-color: #DCEAFF;
    display: flex;
    align-items: center;
    cursor: default !important;
}

.Form {
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    max-width: 1920px;
    justify-content: center;
}

.Left_section {
    width: 45vw;
    height: 100%;
    margin: auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

.Left_section h1 {
    color: #2E2E2E;
    font-family: Arial;
    font-size: min(2vw, 40px);
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
    width: 60%;
    align-self: center;
    justify-self: center;
}

.Left_section h6 {
    width: 60%;
    color: #787878;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-self: center;
    margin-top: 35px;
}

.Left_section a {
    color: #0D5093;

}

.Right_section {
    width: 45vw;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: min(1.3vw, 25px);
    margin-top: 5vh;
    padding-bottom: 5vh;
}

.Input_Field,
.Message,
.Input_Field_1 {
    width: min(17.1875vw, 330px);
    height: 3vh;
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    background: #FFF;
    color: #303030;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Message {
    width: min(40.1vw, 770px) !important;
    height: 180px !important;
}

.Captcha_Block {

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 48px;
    font-style: italic;
    font-weight: 900;
    line-height: 58px;
    letter-spacing: -7px;
    text-align: center;
    display: flex;
    margin-top: 0;

}

.Captcha_Block label {
    font-size: 30px;
}

.Re {
    cursor: pointer;
    margin-left: 10px;
}

.numbers {
    width: 120px;
    height: 58px;
    margin: auto;
    background-color: #FFF;
    text-align: center;
    border-radius: 4px;
}


.Input_Field_1 {
    width: 55px !important;
    margin-left: 10px;
    font-size: 48px !important;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: italic;
    font-weight: 900;
}

.Captcha {
    width: auto;
    height: 40px;
}

.Form_Submit {
    width: 160px;
    height: 48px;
    color: #FFF;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-shrink: 0;
    border-radius: 4px;
    background: #0D5093;
    border: #0D5093;
    margin: auto;
    margin-top: 0px;

}

@media screen and (min-width:600px) and (max-width:1070px) {
    .Form {
        flex-direction: column;
        margin-top: 150px;
    }

    .Left_section h1 {
        width: 160%;
        text-align: center;
        font-size: 26px;
    }

    .Left_section h6 {
        width: 100%;
        text-align: center;
    }

    .Right_section {
        width: 100%;
        margin-top: 50px;
        gap: 40px;
        justify-content: center;
    }

    .Input_Field {
        width: 36.5vw;
        height: 27px;
    }

    .Message {
        width: 83vw !important;
        height: 150px !important;
    }

    .Form_Submit {
        position: relative;
        margin: auto;
        margin-top: 10px;
    }

    .Form_container {
        height: 100vh;
    }
}

@media screen and (min-width:260px) and (max-width:599px) {
    .Form {
        flex-direction: column;
        margin-top: 150px;
    }

    .Left_section h1 {
        width: 200%;
        text-align: center;
        font-size: 18px;
    }

    .Left_section h6 {
        width: 120%;
        text-align: center;
        font-size: 12px;
        margin-top: 0px;
    }

    .Right_section {
        width: 85%;
        margin: auto;
        margin-top: 10px;
        gap: 10px;
        justify-content: center;
    }

    .Input_Field {
        width: 100%;
        height: 17px;
    }

    .Message {
        width: 95vw !important;
        height: 150px !important;
    }

    .Form_Submit {
        position: relative;
        margin: auto;
        margin-top: 10px;
    }

    .Form_container {
        height: 100vh;
    }
}