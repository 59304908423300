.VA .SECTION_2 img {
  transition: transform 1s;
}

.VA .SECTION_2 img:hover {
  transform: scale(1.1);
}

.left_Body_Section_Parts a {
  text-decoration: none;
  cursor: none !important;
}

.SECTION {
  width: 100%;
  height: 620px;
  display: block;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #dceaff;
}

.mobile_section {
  display: none;
}

.Body_section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #dceaff;
  z-index: 100;
  cursor: default;
}

.Body_Section_Parts {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.left_Body_Section_Parts {
  width: 800px;
  height: 620px;
  margin: auto;
  text-align: center;
  background-color: #dceaff;
}

.left_Body_Section_Parts img {
  width: 280px;
  margin: auto;
  margin-top: 200px;
}

.left_Body_Section_Parts p {
  font-size: 38px;
  font-weight: 700;
  color: #303030;
  margin: auto;
  margin-top: 20px;
}

.left_Body_Section_Parts a {
  font-size: 56px;
  font-weight: 900;
  color: #0d5093;
  margin: auto;
  margin-top: 20px;
}

.right_Body_Section_part {
  width: 800px;
  height: 620px;
  margin: auto;
  margin-right: 0px;
  text-align: center;
  background-color: #dceaff;
}

.right_Body_Section_part img {
  width: 800px;
  height: 100%;
  margin: auto;
  margin-right: 0px;
}

@media screen and (min-width: 1300px) and (max-width: 1599px) {
  .SECTION {
    width: 100%;
    height: 540px;
    display: block;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #dceaff;
  }

  .mobile_section {
    display: none;
  }

  .Body_section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #dceaff;
  }

  .Body_Section_Parts {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .left_Body_Section_Parts {
    width: 600px;
    height: 540px;
    margin: auto;
    text-align: center;
    background-color: #dceaff;
  }

  .left_Body_Section_Parts img {
    width: 250px;
    margin: auto;
    margin-top: 200px;
  }

  .left_Body_Section_Parts p {
    font-size: 30px;
    font-weight: 700;
    color: #303030;
    margin: auto;
    margin-top: 20px;
  }

  .left_Body_Section_Parts a {
    font-size: 42px;
    font-weight: 900;
    color: #0d5093;
    margin: auto;
    margin-top: 20px;
  }

  .right_Body_Section_part {
    width: 686px;
    height: 540px;
    margin: auto;
    margin-right: 0px;
    text-align: center;
    background-color: #dceaff;
  }

  .right_Body_Section_part img {
    width: 686px;
    margin: auto;
    margin-right: 0px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .SECTION {
    width: 100%;
    height: 1000px;
  }

  .mobile_section {
    display: none;
  }

  .Body_section {
    width: 1920px;
    height: 100%;
  }

  .Body_Section_Parts {
    width: 100%;
    height: 100%;
  }

  .left_Body_Section_Parts {
    width: 100%;
    height: 400px;
  }

  .left_Body_Section_Parts img {
    width: 250px;
    margin: auto;
    margin-top: 130px;
  }

  .left_Body_Section_Parts p {
    font-size: 30px;
    font-weight: 700;
    color: #303030;
    margin: auto;
    margin-top: 20px;
  }

  .left_Body_Section_Parts a {
    font-size: 42px;
    font-weight: 900;
    color: #0d5093;
    margin: auto;
    margin-top: 20px;
  }

  .right_Body_Section_part {
    width: 100%;
    height: auto;
    margin: auto;
    margin-right: 0px;
    text-align: center;
    background-color: #dceaff;
  }

  .right_Body_Section_part img {
    width: 686px;
    margin: auto;
    border-radius: 12px;
    margin-top: 30px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .SECTION {
    width: 100%;
    height: 750px;
    margin-top: 0;
  }

  .mobile_section {
    display: none;
  }

  .Body_section {
    width: 1920px;
    height: 100%;
  }

  .Body_Section_Parts {
    width: 100%;
    height: 100%;
  }

  .left_Body_Section_Parts {
    width: 100%;
    height: 350px;
  }

  .left_Body_Section_Parts img {
    width: 200px;
    margin: auto;
    margin-top: 110px;
  }

  .left_Body_Section_Parts p {
    font-size: 26px;
    font-weight: 700;
    color: #303030;
    margin: auto;
    margin-top: 20px;
  }

  .left_Body_Section_Parts a {
    font-size: 37px;
    font-weight: 900;
    color: #0d5093;
    margin: auto;
    margin-top: 20px;
  }

  .right_Body_Section_part {
    width: 100%;
    height: auto;
    margin: auto;
    margin-right: 0px;
    text-align: center;
    background-color: #dceaff;
  }

  .right_Body_Section_part img {
    width: 500px;
    margin: auto;
    border-radius: 12px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  /* video */
  .SECTION {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .mobile_section {
    display: none;
  }

  .Body_section {
    width: 1920px;
    height: 100%;
  }

  .Body_Section_Parts {
    width: 100%;
    height: 100%;
  }

  .left_Body_Section_Parts {
    width: 100%;
    height: 300px;
  }

  .left_Body_Section_Parts img {
    width: 120px;
    margin: auto;
    margin-top: 90px;
  }

  .left_Body_Section_Parts p {
    font-size: 20px;
    font-weight: 700;
    color: #303030;
    margin: auto;
    margin-top: 15px;
  }

  .left_Body_Section_Parts a {
    font-size: 26px;
    font-weight: 900;
    color: #0d5093;
    margin: auto;
    margin-top: 20px;
  }

  .right_Body_Section_part {
    width: 100%;
    height: auto;
    margin: auto;
    margin-right: 0px;
    text-align: center;
    background-color: #dceaff;
  }

  .right_Body_Section_part img {
    width: 100%;
    margin: auto;
    margin-top: 0px;
  }
}

.SECTION_2 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  scroll-behavior: smooth;
  background-color: #f2f2f2;
}

.section_3_Width {
  display: none;
}

.section_2_Body_Section {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_2_Width {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_first {
  width: 100%;
  height: 1200px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_2_box_1 {
  width: 640px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_1_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_box_1_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_2 {
  width: 320px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_2_img {
  overflow: hidden;
  width: 320px;
  height: 450px;
  position: relative;
  margin: auto;
  cursor: none !important;
}

.section_2_box_2_img img {
  width: 100%;
  height: 100%;
}

.section_2_secound {
  width: 100%;
  height: 1200px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.section_2_box_3 {
  width: 320px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_3_img {
  overflow: hidden;
  width: 320px;
  height: 450px;
  position: relative;
  margin: auto;
  cursor: none !important;
}

.section_2_box_3_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_4 {
  width: 640px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_4_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_box_4_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_5 {
  width: 640px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_5_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_box_5_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_6 {
  width: 320px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_6_img {
  overflow: hidden;
  width: 320px;
  height: 450px;
  position: relative;
  margin: auto;
  cursor: none !important;
}

.section_2_box_6_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_7 {
  width: 320px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_7_img {
  overflow: hidden;
  width: 320px;
  height: 450px;
  position: relative;
  margin: auto;
  cursor: none !important;
}

.section_2_box_7_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_8 {
  width: 640px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_8_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_box_8_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_9 {
  width: 640px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_9_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.section_2_box_9_img img {
  width: 100%;
  height: 100%;
}

.section_2_box_10 {
  width: 320px;
  height: 840px;
  position: relative;
  padding: 1px 1px;
  background: #f2f2f2;
  margin: 1px;
  margin: auto;
  cursor: none !important;
}

.section_2_box_10_img {
  overflow: hidden;
  width: 320px;
  height: 450px;
  position: relative;
  margin: auto;
  cursor: none !important;
}

.section_2_box_10_img img {
  width: 100%;
  height: 100%;
}

.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-in-out;
  z-index: 99;
  text-align: center;
}

.cursor::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid;
  border-color: #d9d9d9;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s ease-in-out;
  z-index: 99999;
  text-align: center;
}

.hovered,
.hovered1,
.hovered2,
.hovered3,
.hovered4,
.hovered5,
.hovered6,
.hovered7,
.hovered8,
.hovered9 {
  transform: translate(-50%, -50%) scale(2);
}

.hovered::before,
.hovered1::before,
.hovered2::before,
.hovered3::before,
.hovered4::before,
.hovered5::before,
.hovered6::before,
.hovered7::before,
.hovered8::before,
.hovered9::before {
  content: "\AView \AProduct";
  white-space: pre;
  position: absolute;
  line-height: 10px;
  top: 50%;
  left: 50%;
  border: 0px;
  transition: transform 0.5s ease-in-out;
  transform: translate(-50%, -50%) scale(1);
  color: white;
  font-weight: 500;
  font-size: 8px;
  opacity: 1;
  text-align: center;
}

.hovered::before {
  background-color: #5f3239;
}

.hovered1::before {
  background-color: #ef4f51;
}

.hovered2::before {
  background-color: #01e7ff;
}

.hovered3::before {
  color: #2e2e2e;
  background-color: #ffffff;
}

.hovered4::before {
  background-color: #00a800;
}

.hovered6::before {
  background-color: #2022ff;
}

.hovered5::before {
  background-color: #895794;
}

.hovered7::before {
  background-color: #383c46;
}

.hovered8::before {
  background-color: #df8908;
}

.hovered9::before {
  color: #2e2e2e;
  background-color: #93aad8;
}

.section_2_box_2_img,
.section_2_box_6_img,
.section_2_box_7_img,
.section_2_box_3_img,
.section_2_box_10_img {
  transition: transform 0.5s;
  z-index: 1;
}

.section_2_box_1 a {
  text-decoration: none;
}

.section_2_box_2 a {
  text-decoration: none;
}

@media screen and (min-width: 1100px) and (max-width: 1299px) {
  .SECTION_2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    scroll-behavior: smooth;
  }

  .section_3_Width {
    display: none;
  }

  .section_2_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_Width {
    width: 85%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_first {
    width: 100%;
    height: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_box_1 {
    width: 500px;
    height: 680px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_1_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_1_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_2 {
    width: 260px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_2_img {
    overflow: hidden;
    width: 260px;
    height: 380px;
    position: relative;
    margin: auto;
    margin-top: 300px;
    cursor: none !important;
  }

  .section_2_box_2_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_secound {
    width: 100%;
    height: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_box_3 {
    width: 260px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_3_img {
    overflow: hidden;
    width: 260px;
    height: 380px;
    position: relative;
    margin: auto;
    margin-top: 330px;
    cursor: none !important;
  }

  .section_2_box_3_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_4 {
    width: 500px;
    height: 680px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_4_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_4_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_5 {
    width: 500px;
    height: 680px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_5_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_5_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_6 {
    width: 260px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_6_img {
    overflow: hidden;
    width: 260px;
    height: 380px;
    position: relative;
    margin: auto;
    margin-top: 340px;
    cursor: none !important;
  }

  .section_2_box_6_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_7 {
    width: 260px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_7_img {
    overflow: hidden;
    width: 260px;
    height: 380px;
    position: relative;
    margin: auto;
    margin-top: 330px;
    cursor: none !important;
  }

  .section_2_box_7_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_8 {
    width: 500px;
    height: 680px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_8_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_8_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_9 {
    width: 500px;
    height: 680px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_9_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_9_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_10 {
    width: 260px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_10_img {
    overflow: hidden;
    width: 260px;
    height: 380px;
    position: relative;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_10_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_1 a {
    text-decoration: none;
  }

  .section_2_box_2 a {
    text-decoration: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .SECTION_2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    scroll-behavior: smooth;
  }

  .section_3_Width {
    display: none;
  }

  .section_2_Body_Section {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_Width {
    width: 85%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_first {
    width: 100%;
    height: 900px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_box_1 {
    width: 380px;
    height: 540px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_1_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_1_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_2 {
    width: 200px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_2_img {
    overflow: hidden;
    width: 200px;
    height: 300px;
    position: relative;
    margin: auto;
    margin-top: 70px;
    cursor: none !important;
  }

  .section_2_box_2_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_secound {
    width: 100%;
    height: 900px;
    display: flex;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_2_box_3 {
    width: 200px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_3_img {
    overflow: hidden;
    width: 200px;
    height: 300px;
    position: relative;
    margin: auto;
    margin-top: -80px;
    cursor: none !important;
  }

  .section_2_box_3_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_4 {
    width: 380px;
    height: 540px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_4_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_4_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_5 {
    width: 380px;
    height: 540px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_5_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_5_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_6 {
    width: 200px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_6_img {
    overflow: hidden;
    width: 200px;
    height: 300px;
    position: relative;
    margin: auto;
    margin-top: -250px;
    cursor: none !important;
  }

  .section_2_box_6_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_7 {
    width: 200px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_7_img {
    overflow: hidden;
    width: 200px;
    height: 300px;
    position: relative;
    margin: auto;
    margin-top: -450px;
    cursor: none !important;
  }

  .section_2_box_7_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_8 {
    width: 380px;
    height: 540px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_8_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_8_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_9 {
    width: 380px;
    height: 540px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_9_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_2_box_9_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_10 {
    width: 200px;
    height: 840px;
    position: relative;
    padding: 1px 1px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_2_box_10_img {
    overflow: hidden;
    width: 200px;
    height: 300px;
    position: relative;
    margin: auto;
    margin-top: 70px;
    cursor: none !important;
  }

  .section_2_box_10_img img {
    width: 100%;
    height: 100%;
  }

  .section_2_box_1 a {
    text-decoration: none;
  }

  .section_2_box_2 a {
    text-decoration: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .section_2_Width {
    display: none;
  }

  .cursor {
    display: none;
  }

  .section_3_Width {
    width: 300px;
    height: 100%;
    margin: auto;
    display: block;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
  }

  .section_3_first {
    width: 100%;
    height: auto;
    display: flex;
    margin: auto;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .section_3_box_1 {
    width: 300px;
    height: 440px;
    position: relative;
    padding: 30px 0px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_3_box_1_img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_box_1_img img {
    width: 100%;
    height: 100%;
  }

  .section_3_box_2 {
    width: 300px;
    height: 440px;
    position: relative;
    padding: 30px 0px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_3_box_2_img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_box_2_img img {
    width: 100%;
    height: 100%;
  }

  .section_3_box_3 {
    width: 300px;
    height: 440px;
    position: relative;
    padding: 30px 0px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_3_box_3_img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_box_3_img img {
    width: 100%;
    height: 100%;
  }

  .section_3_box_4 {
    width: 300px;
    height: 440px;
    position: relative;
    padding: 30px 0px;
    background: #f2f2f2;
    margin: 1px;
    margin: auto;
    cursor: none !important;
  }

  .section_3_box_4_img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
  }

  .section_3_box_4_img img {
    width: 100%;
    height: 100%;
  }

  .section_3_box_4_heading {
    width: 100%;
    height: 80px;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: -100px;
  }

  .section_3_box_4_heading::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  .section_3_box_4_heading h2 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    margin: auto;
    margin-top: 45px;
    table-layout: fixed;
    border-collapse: collapse;
    z-index: 2;
    position: relative;
    color: #ffffff;
  }

  .section_3_box_1 a {
    text-decoration: none;
  }

  .section_3_box_2 a {
    text-decoration: none;
  }
}
