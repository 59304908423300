section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.Time_Attendance {
  width: 1920px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.first_Section {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 100px;
}

.Left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 80px;
}

.Left img {
  width: 100%;
  border-radius: 8px;
  margin: auto;
}

.right {
  width: 50%;
  height: 100%;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 80px;
}

.right h1 {
  color: #2e2e2e;
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  margin: auto;
  margin-left: 40px;
}

.right a {
  color: #0d5093;
  font-size: 20px;
  line-height: 55px;
  text-align: justify;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  margin-left: 40px;
  text-decoration: none;
  cursor: default;
}

.right p {
  color: #565656;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  font-weight: 400;
  margin-left: 40px;
}

.Left_Mobile {
  display: none;
}

.Text {
  color: #565656;
  line-height: 26px;
  font-weight: 400;
  text-align: justify;
}

.section_3_content_1_1 span {
  color: #0d5093;
}

.section_3_content_1_1 {
  margin-top: 10px;
}

.section_3_content_2_1 {
  margin-top: 10px;
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  section {
    width: 100%;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
  }

  .first_Section {
    width: 85%;
    height: 100%;
    margin-top: 100px;
  }

  .Left {
    width: 50%;
    height: 100%;
    margin-top: 90px;
  }

  .Left img {
    width: 100%;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 50%;
    height: 100%;
    margin-top: 80px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 37px;
    line-height: 46px;
    margin-left: 30px;
  }

  .right a {
    color: #0d5093;
    font-size: 20px;
    line-height: 52px;
    margin-left: 30px;
  }

  .right p {
    color: #565656;
    font-size: 18px;
    line-height: 30px;
    margin-left: 30px;
  }

  .Left_Mobile {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  section {
    width: 100%;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
  }

  .first_Section {
    width: 85%;
    height: 100%;
    margin-top: 100px;
  }

  .Left {
    width: 50%;
    height: 100%;
    margin-top: 90px;
  }

  .Left img {
    width: 100%;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 50%;
    height: 100%;
    margin-top: 80px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 10px;
    margin-left: 30px;
  }

  .right a {
    color: #0d5093;
    font-size: 19px;
    line-height: 40px;
    margin-left: 30px;
  }

  .right p {
    color: #565656;
    font-size: 17px;
    line-height: 26px;
    margin-left: 30px;
  }

  .Left_Mobile {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .first_Section {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 100px;
  }

  .Left {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 80px;
  }

  .Left img {
    width: 600px;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 100%;
    height: 60%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 32px;
    line-height: 60px;
    font-weight: 600;
    margin: auto;
  }

  .right a {
    color: #0d5093;
    font-size: 18px;
    line-height: 40px;
    margin: auto;
    font-weight: 700;
  }

  .right p {
    color: #565656;
    font-size: 16px;
    line-height: 30px;
    margin: auto;
    font-weight: 400;
  }

  .Left_Mobile {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .first_Section {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 100px;
  }

  .Left {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 80px;
  }

  .Left img {
    width: 550px;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 100%;
    height: 60%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 32px;
    line-height: 60px;
    font-weight: 600;
    margin: auto;
  }

  .right a {
    color: #0d5093;
    font-size: 18px;
    line-height: 40px;
    margin: auto;
    font-weight: 700;
  }

  .right p {
    color: #565656;
    font-size: 16px;
    line-height: 30px;
    margin: auto;
    font-weight: 400;
  }

  .Left_Mobile {
    display: none;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .first_Section {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 100px;
  }

  .Left {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 80px;
  }

  .Left img {
    width: 400px;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 100%;
    height: 60%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 20px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    margin: auto;
  }

  .right a {
    color: #0d5093;
    font-size: 16px;
    line-height: 25px;
    margin: auto;
    font-weight: 700;
  }

  .right p {
    color: #565656;
    font-size: 16px;
    line-height: 28px;
    margin: auto;
    font-weight: 400;
    text-align: justify;
    margin-top: 20px;
  }

  .Left_Mobile {
    display: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 479px) {
  section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Time_Attendance {
    width: 1920px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .first_Section {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 100px;
  }

  .Left {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 40px;
  }

  .Left img {
    width: 280px;
    border-radius: 8px;
    margin: auto;
  }

  .right {
    width: 100%;
    height: 60%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 30px;
  }

  .right h1 {
    color: #2e2e2e;
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
    margin: auto;
  }

  .right a {
    color: #0d5093;
    font-size: 16px;
    line-height: 25px;
    margin: auto;
    font-weight: 700;
  }

  .right p {
    color: #565656;
    font-size: 16px;
    line-height: 26px;
    margin: auto;
    font-weight: 400;
    text-align: justify;
    margin-top: 20px;
  }

  .Left_Mobile {
    display: none;
  }
}
