.Section_Content_Container {
  width: 100%;
  height: auto;
  display: flex;
}

.Section1 {
  width: 1920px;
  height: auto;
  display: flex;
  margin: auto;
}

.Content_Container {
  width: 85%;
  height: auto;
  margin: auto;
}

.Content_Container h1 {
  color: #2e2e2e;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.6;
  margin: auto;
  margin-top: 0px;
}

.Content_Container p {
  color: #565656;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.6;
  margin: auto;
  margin-top: 20px;
  text-align: justify;
}

.Content_Container p strong {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 19x;
  line-height: 1.6;
  text-align: justify;
}

@media screen and (min-width: 700px) and (max-width: 1280px) {
  .Section_Content_Container {
    width: 100%;
    margin-top: 0px;
  }

  .Content_Container {
    width: 90%;
  }

  .Content_Container h1 {
    color: #2e2e2e;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.6;
    margin: auto;
    margin-top: 0px;
  }

  .Content_Container p {
    color: #565656;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.6;
    margin: auto;
    margin-top: 14px;
    text-align: justify;
  }

  .Content_Container p strong {
    color: #2e2e2e;
    font-weight: 600;
    font-size: 16x;
    line-height: 1.6;
    text-align: justify;
  }
}

@media screen and (min-width: 300px) and (max-width: 699px) {
  .Section_Content_Container {
    width: 100%;
    margin-top: 0px;
  }

  .Content_Container {
    width: 90%;
  }

  .Content_Container h1 {
    color: #2e2e2e;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    margin: auto;
    margin-top: -20px;
  }

  .Content_Container p {
    color: #565656;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    margin: auto;
    margin-top: 14px;
    text-align: justify;
  }

  .Content_Container p strong {
    color: #2e2e2e;
    font-weight: 600;
    font-size: 14x;
    line-height: 1.6;
    text-align: justify;
  }
}

.Scroll_Image_Gallery {
  width: 1350px;
  height: auto;
  margin: auto;
}

.Image_List {
  width: 100%;
  height: auto;
  background-color: red;
}

.wrapper1 {
  width: 100%;
  float: right;
  height: auto;
  display: flex;
  overflow-x: auto;
  margin-top: 20px;
}

.wrapper1::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Card_Box {
  min-width: 420px;
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  border-radius: 20px;
}

.Card_Content {
  min-width: 380px;
  height: 360px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
  border-radius: 20px;
}

.Images_Data {
  width: 500px;
  height: 100%;
  margin-left: 20px;
  background-color: bisque;
}

.Scroll_Button {
  width: 100%;
  height: 150px;
  display: flex;
}

.Scroll_Button_Section {
  width: auto;
  height: 50px;
  margin: auto;
  display: flex;
  margin-top: 20px;
}

.Circle {
  width: 30px;
  height: 100%;
  display: flex;
}

.Circle img {
  width: auto;
  margin: auto;
}

.Circle_Button {
  width: 50px;
  height: 100%;
  display: flex;
}

.Circle_Button img {
  width: auto;
  margin: auto;
  cursor: pointer;
}

.Line {
  width: 60px;
  height: 100%;
  display: flex;
}

.Line img {
  width: auto;
  margin: auto;
}

@media screen and (min-width: 1024px) and (max-width: 1359px) {
  .Scroll_Image_Gallery {
    width: 1000px;
    height: auto;
    margin: auto;
  }

  .Image_List {
    width: 100%;
    height: auto;
    background-color: red;
  }

  .wrapper1 {
    width: 100%;
    float: right;
    height: auto;
    display: flex;
    overflow-x: auto;
    margin-top: 20px;
  }

  .wrapper1::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .Card_Box {
    min-width: 300px;
    height: 280px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 15px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 20px;
  }

  .Card_Content {
    min-width: 320px;
    height: 280px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    border-radius: 20px;
  }

  .Card_Content img {
    min-width: 280px;
    height: 260px;
  }

  .Images_Data {
    width: 500px;
    height: 100%;
    margin-left: 20px;
    background-color: bisque;
  }

  .Scroll_Button {
    width: 100%;
    height: 150px;
    display: flex;
  }

  .Scroll_Button_Section {
    width: auto;
    height: 50px;
    margin: auto;
    display: flex;
    margin-top: 20px;
  }

  .Circle {
    width: 30px;
    height: 100%;
    display: flex;
  }

  .Circle img {
    width: auto;
    margin: auto;
  }

  .Circle_Button {
    width: 50px;
    height: 100%;
    display: flex;
  }

  .Circle_Button img {
    width: auto;
    margin: auto;
    cursor: pointer;
  }

  .Line {
    width: 60px;
    height: 100%;
    display: flex;
  }

  .Line img {
    width: auto;
    margin: auto;
  }
}

@media screen and (min-width: 300px) and (max-width: 1023px) {
  .Scroll_Image_Gallery {
    display: none;
  }
}

.New_Opening_Form {
  width: 85%;
  height: auto;
  margin: auto;
}

.FormHeading {
  width: 100%;
  height: 100px;
}

.FormHeading h1 {
  text-align: center;
  color: #2e2e2e;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
}

.FormHeading p {
  color: #0d5093;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-top: 0;
}

.Swip_Block_Container {
  width: 100%;
  height: 560px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.Swip_Button_Container {
  width: 100%;
  height: 460px;
}

.Swipe {
  width: 50%;
  height: 120px;
  margin: auto;
  display: flex;
}

.Swipe_Pannel {
  width: 50%;
  height: 100%;
  display: flex;
}

.Swipe_Pannel button {
  width: 250px;
  color: white;
  font-size: 18px;
  padding: 12px 50px;
  background-color: #0d5093;
  border: none;
  margin: auto;
  border-radius: 8px;
}

.Swipe_Pannel_A {
  width: 50%;
  height: 100%;
  display: flex;
}

.Swipe_Pannel_A button {
  width: 250px;
  color: #2e2e2e;
  font-size: 18px;
  padding: 12px 50px;
  border: 1px solid #d9d9d9;
  margin: auto;
  border-radius: 8px;
  background-color: transparent;
}

.Swipe_Content {
  width: 75%;
  height: calc(100% - 120px);
  margin: auto;
}

.Swipe_Content h1 {
  color: #2e2e2e;
  text-align: center;
  font-weight: 900;
  line-height: 2.5;
  font-size: 20px;
}

.Swipe_Content p {
  color: #565656;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}

.Button_For_Apply {
  width: 100%;
  height: 100px;
  display: flex;
}

.Button_For_Apply button {
  color: white;
  font-size: 18px;
  padding: 10px 50px;
  background-color: #0d5093;
  border-radius: 8px;
  border: none;
  margin: auto;
  margin-top: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .New_Opening_Form {
    width: 85%;
    height: auto;
    margin: auto;
  }

  .FormHeading {
    width: 100%;
    height: 100px;
  }

  .FormHeading h1 {
    text-align: center;
    color: #2e2e2e;
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
  }

  .FormHeading p {
    color: #0d5093;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin-top: 0;
  }

  .Swip_Block_Container {
    width: 100%;
    height: 500px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .Swip_Button_Container {
    width: 100%;
    height: 400px;
  }

  .Swipe {
    width: 60%;
    height: 120px;
    margin: auto;
    display: flex;
  }

  .Swipe_Pannel {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel button {
    width: 200px;
    color: white;
    font-size: 14px;
    padding: 12px 40px;
    background-color: #0d5093;
    border: none;
    margin: auto;
    border-radius: 8px;
  }

  .Swipe_Pannel_A {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel_A button {
    width: 200px;
    color: #2e2e2e;
    font-size: 14px;
    padding: 12px 40px;
    border: 1px solid #d9d9d9;
    margin: auto;
    border-radius: 8px;
    background-color: transparent;
  }

  .Swipe_Content {
    width: 80%;
    height: calc(100% - 120px);
    margin: auto;
  }

  .Swipe_Content h1 {
    color: #2e2e2e;
    text-align: center;
    font-weight: 900;
    line-height: 2.5;
    font-size: 18px;
  }

  .Swipe_Content p {
    color: #565656;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }

  .Button_For_Apply {
    width: 100%;
    height: 100px;
    display: flex;
  }

  .Button_For_Apply button {
    color: white;
    font-size: 16px;
    padding: 10px 40px;
    background-color: #0d5093;
    border-radius: 8px;
    border: none;
    margin: auto;
    margin-top: 0;
  }
}

@media screen and (min-width: 700px) and (max-width: 1023px) {
  .New_Opening_Form {
    width: 85%;
    height: auto;
    margin: auto;
  }

  .FormHeading {
    width: 100%;
    height: 100px;
  }

  .FormHeading h1 {
    text-align: center;
    color: #2e2e2e;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
  }

  .FormHeading p {
    color: #0d5093;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-top: 0;
  }

  .Swip_Block_Container {
    width: 100%;
    height: 500px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .Swip_Button_Container {
    width: 100%;
    height: 400px;
  }

  .Swipe {
    width: 80%;
    height: 120px;
    margin: auto;
    display: flex;
  }

  .Swipe_Pannel {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel button {
    width: 200px;
    color: white;
    font-size: 14px;
    padding: 8px 30px;
    background-color: #0d5093;
    border: none;
    margin: auto;
    border-radius: 8px;
  }

  .Swipe_Pannel_A {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel_A button {
    width: 200px;
    color: #2e2e2e;
    font-size: 14px;
    padding: 8px 30px;
    border: 1px solid #d9d9d9;
    margin: auto;
    border-radius: 8px;
    background-color: transparent;
  }

  .Swipe_Content {
    width: 85%;
    height: calc(100% - 120px);
    margin: auto;
  }

  .Swipe_Content h1 {
    color: #2e2e2e;
    text-align: center;
    font-weight: 900;
    line-height: 2.5;
    font-size: 16px;
  }

  .Swipe_Content p {
    color: #565656;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .Button_For_Apply {
    width: 100%;
    height: 100px;
    display: flex;
  }

  .Button_For_Apply button {
    color: white;
    font-size: 14px;
    padding: 10px 40px;
    background-color: #0d5093;
    border-radius: 8px;
    border: none;
    margin: auto;
    margin-top: 0;
  }
}

@media screen and (min-width: 300px) and (max-width: 699px) {
  .New_Opening_Form {
    width: 90%;
    height: auto;
    margin: auto;
  }

  .FormHeading {
    width: 100%;
    height: auto;
    margin-top: -30px;
  }

  .FormHeading h1 {
    text-align: center;
    color: #2e2e2e;
    font-size: 23px;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .FormHeading p {
    color: #0d5093;
    width: 85%;
    margin: auto;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Swip_Block_Container {
    width: 100%;
    height: auto;
    border: 0px solid #ffffff;
    border-radius: 8px;
  }

  .Swip_Button_Container {
    width: 100%;
    height: auto;
  }

  .Swipe {
    width: 100%;
    height: 120px;
    margin: auto;
    display: flex;
  }

  .Swipe_Pannel {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel button {
    width: 140px;
    color: white;
    font-size: 12px;
    padding: 8px 20px;
    background-color: #0d5093;
    border: none;
    margin: auto;
    border-radius: 8px;
  }

  .Swipe_Pannel_A {
    width: 50%;
    height: 100%;
    display: flex;
  }

  .Swipe_Pannel_A button {
    width: 140px;
    color: #2e2e2e;
    font-size: 12px;
    padding: 8px 20px;
    border: 1px solid #d9d9d9;
    margin: auto;
    border-radius: 8px;
    background-color: transparent;
  }

  .Swipe_Content {
    width: 100%;
    height: calc(100% - 120px);
    margin: auto;
  }

  .Swipe_Content h1 {
    color: #2e2e2e;
    text-align: center;
    font-weight: 900;
    line-height: 1.5;
    font-size: 16px;
  }

  .Swipe_Content p {
    color: #565656;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  .Button_For_Apply {
    width: 100%;
    height: 100px;
    display: flex;
  }

  .Button_For_Apply button {
    color: white;
    font-size: 14px;
    padding: 10px 40px;
    background-color: #0d5093;
    border-radius: 8px;
    border: none;
    margin: auto;
  }
}

.Section_Content_Container {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}

section {
  width: 1920px;
  height: auto;
  display: flex;
  margin: auto;
}

.internship {
  width: 85%;
  height: auto;
  margin: auto;
  display: flex;
}

.internship_box {
  width: 380px;
  height: 80px;
  margin: auto;
  display: flex;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.Button {
  width: auto;
  height: auto;
  margin: auto;
  margin-left: 0px;
  border-radius: 8px;
}

.Button button {
  padding: 20px 28px;
  border-radius: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.Button a {
  color: #2e2e2e;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
}

.image {
  width: 48px;
  height: 48px;
  margin: auto;
  margin-right: 20px;
  display: flex;
  background-color: aqua;
}

.image img {
  width: 100%;
}

.section_10 {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.section_10_Body_Section {
  width: 1920px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.section_10_Body_Section_parts {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.catalog_button {
  width: auto;
  height: auto;
  margin: auto;
  margin-top: 0px;
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
}

.catalog_button button {
  color: #0d5093;
  padding: 16px 42px;
  border-radius: 4px;
  width: 800;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.catalog_button a {
  color: #2e2e2e;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
}

.catalog_button button img {
  margin-left: 24px;
  width: 44px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section_10 {
    width: 100%;
    height: 150px;
  }

  .section_10_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_10_Body_Section_parts {
    width: 85%;
    height: 100%;
  }

  .catalog_button button {
    color: #0d5093;
    padding: 12px 22px;
    border-radius: 4px;
    width: 800;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .catalog_button a {
    color: #2e2e2e;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
  }

  .catalog_button button img {
    margin-left: 24px;
    width: 34px;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .section_10 {
    width: 100%;
    height: 100px;
  }

  .section_10_Body_Section {
    width: 1920px;
    height: 100%;
  }

  .section_10_Body_Section_parts {
    width: 85%;
    height: 100%;
  }

  .catalog_button button {
    color: #0d5093;
    padding: 10px 16px;
    border-radius: 4px;
    width: 800;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .catalog_button a {
    color: #2e2e2e;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
  }

  .catalog_button button img {
    margin-left: 24px;
    width: 24px;
  }
}
